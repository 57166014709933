import { GlobalUserPermissions } from '@client/Context/UserPermissions';
import { User } from '@sprint/sprint-react-components';
import _ from 'lodash';
import { UserPermissionLevel } from '../Models/Enums';

export enum ModulePermissions {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
    INVISIBLE = 'INVISIBLE',
}

export type DataGridColumnPermission = 'active' | 'invisible' | 'disabled';

export interface Permissions {
    [key: string]: ModulePermissions;
}

class UserPermissionsHelper {
    public static isUserAnAdmin = (user: User): boolean => {
        // If user has a type of master then it has admin
        // level permissions
        if (user.type === UserPermissionLevel.ADMIN) {
            return true;
        }

        const userLevel: string = _.capitalize(user.level);
        if (userLevel === UserPermissionLevel.ADMIN) {
            return true;
        }

        return false;
    };

    public static isUserAnAdminOrManager = (user: User): boolean => {
        if (UserPermissionsHelper.isUserAnAdmin(user)) {
            return true;
        } else {
            const userLevel: string = _.capitalize(user.level);
            if (userLevel === UserPermissionLevel.MANAGER) {
                return true;
            }
        }

        return false;
    };

    public static makeDataGridColumnPermission = (name: string): DataGridColumnPermission => {
        let permission: DataGridColumnPermission = 'invisible';
        if (GlobalUserPermissions === undefined) {
            return permission;
        }
        switch (GlobalUserPermissions[name]) {
            case ModulePermissions.ENABLED:
                permission = 'active';
                break;
            case ModulePermissions.DISABLED:
                permission = 'disabled';
                break;
        }
        return permission;
    };
}

export default UserPermissionsHelper;
