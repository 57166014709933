import OwnedByDropdown from '@client/Applications/DataGrids/Grids/CRM/OwnedByDropdown';
import { ModulePermissions } from '@client/Applications/DataGrids/HelperFunctions/UserPermissionsHelper';
import ClientTagPill from '@client/CommonComponents/ClientTags/ClientTagPill';
import TagsEditDropdown from '@client/CommonComponents/ClientTags/TagsEditDropdown';
import { TagsRolloutEnabledContext } from '@client/Context/TagsRolloutEnabled';
import { UserPermissionsContext } from '@client/Context/UserPermissions';
import {
    AppEvent,
    AsyncDropDownPaginated,
    DropDown,
    EventBusInstance,
    LogLevel,
    lookupValidator,
    OptionTypeBase,
    OptionTypeBaseUserFormatter,
    PendingButton,
    SearchQuery,
    showBanner,
    Sisp,
    SortOrder,
} from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { BaseSyntheticEvent, FunctionComponent, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { DictionaryContext, RepositoryFactoryContext } from '../..';
import { ucwords } from '../../../../Helpers/StringHelper';
import { AllCountriesRequest } from '../../Api/AllCountriesRequest';
import { OrganisationsRequest } from '../../Api/OrganisationsRequest';
import Organisation from '../../Models/Organisation';
import CustomPropertyForm, { AvailablePropertyTypes } from '../CustomProperties/CustomPropertyForm';

interface Props {
    uniqueKey: string;
    onSuccess: (event: any) => Promise<boolean>;
}

type FormData = {
    id: number;
    organisation_name: string;
    organisation_sector: string;
    email?: string;
    telephone: string;
    website: string;
    edu_data_package?: string;
    type_name: string;
    address1: string;
    address2: string;
    address3: string;
    address4: string;
    county: string;
    region: string;
    postcode: string;
    country: string;
    owned_by?: OptionTypeBase;
    tag_ids?: number[];
};

const OrganisationsEditSisp: FunctionComponent<Props> = (props: Props) => {
    const {
        control,
        register,
        handleSubmit,
        setValue,
        watch,
        reset,
        setError,
        formState: { errors, isSubmitting },
    } = useForm<FormData>({ mode: 'all' });

    const [shown, setShown] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);

    // Repositories
    const organisationsRepository = useContext(RepositoryFactoryContext).getApiRepository(new OrganisationsRequest());
    const countryRepository = useContext(RepositoryFactoryContext).getApiRepository(new AllCountriesRequest());
    const dictionary = useContext(DictionaryContext);
    const tagsRolloutEnabled = useContext(TagsRolloutEnabledContext);
    const permissions = useContext(UserPermissionsContext);
    const customTagsEnabled = permissions.customTags == ModulePermissions.ENABLED;

    const customProperties: any = JSON.parse(
        String((document.getElementById('custom-properties') as HTMLInputElement).value),
    );

    // Fields
    const newEmail = watch('email');
    // Form state
    const [organisation, setOrganisation] = useState<Organisation>();
    const [sector, setSector] = useState<OptionTypeBase>();
    const [country, setCountry] = useState<OptionTypeBase>();

    const [customPropertyValues, setCustomPropertyValues] = useState<any>();

    // State: Tags
    const [tags, setTags] = useState<{ value: number; label: any }[]>();

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<Organisation>) => {
            // Set the form state and values from the event object.
            // If another sisp open event was triggered, we close this SISP and reset the form.
            if (event.target !== props.uniqueKey) {
                resetForm();
                setShown(false);
                return;
            }

            setOrganisation(event.message);
            setValue('id', event.message.id);
            setValue('type_name', event.message.business_type ?? '');
            setValue('edu_data_package', event.message.edu_data_package ?? '');
            setValue('organisation_name', event.message.name ?? '');
            setValue('telephone', event.message.telephone ?? '');
            setValue('email', event.message.email ?? '');
            setValue('website', event.message.website ?? '');
            setValue(
                'owned_by',
                event.message.owned_by && (OptionTypeBaseUserFormatter(event.message.owned_by) as OptionTypeBase),
            );
            // Sector
            setValue('organisation_sector', event.message.sector ?? '');
            if (event.message.sector) {
                setSector({ value: event.message.sector, label: event.message.sector });
            }
            // address
            setValue('address1', event.message.address1 ?? '');
            setValue('address2', event.message.address2 ?? '');
            setValue('address3', event.message.address3 ?? '');
            setValue('address4', event.message.address4 ?? '');
            setValue('county', event.message.county ?? '');
            setValue('region', event.message.region ?? '');
            setValue('postcode', event.message.postcode ?? '');
            setValue('country', event.message.country ?? '');
            if (event.message.country) {
                setCountry({ value: event.message.country_code, label: event.message.country });
            }

            if (tagsRolloutEnabled && customTagsEnabled) {
                setTags(
                    event.message.tags?.map((t) => {
                        return {
                            value: t.id ?? 0,
                            label: <ClientTagPill tag={t} />,
                        };
                    }) ?? [],
                );
            }

            // Set custom property values
            setCustomPropertyValues(event.message.custom_properties);

            setSubmitting(false);
            setShown(true);
        });
    }, [shown]);

    const handleEditRow = async (
        data: FieldValues,
        event?: BaseSyntheticEvent<unknown, any, any>,
    ): Promise<boolean> => {
        return organisationsRepository
            .update({ ...data, owned_by: data.owned_by?.value })
            .then((results: any) => {
                props.onSuccess([results.data]);
                showBanner({
                    message: ucwords(dictionary['organisation']) + ' updated successfully',
                    level: LogLevel.SUCCESS,
                });
                const buttonClicked = (event?.nativeEvent as SubmitEvent | undefined)?.submitter;
                if (buttonClicked?.dataset.redirectTo) {
                    window.location.href = `/subscribers/organisations/view/${results.data.id}`;
                }
                return Promise.resolve(true);
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to update organisation - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return Promise.resolve(false);
            });
    };

    const onSubmitForm = async (data: FieldValues, event?: BaseSyntheticEvent<unknown, any, any>) => {
        event?.preventDefault();
        setSubmitting(true);
        if ((await validate()) && (await handleEditRow(data, event))) {
            resetForm();
            setShown(false);
        } else {
            setSubmitting(false);
        }
    };

    const resetForm = () => {
        setCustomPropertyValues(null);
        setSubmitting(false);
        setCountry(undefined);
        setSector(undefined);
        reset();
    };

    const loadCountries = (filter?: string, page?: number): Promise<{ value: number; label: JSX.Element }[]> => {
        // Fetch all users for client account
        const query = new SearchQuery(page ?? 1, 100, 'id', SortOrder.ASC, filter);
        return countryRepository
            .search(query)
            .then((results) => {
                return _.map(
                    _.filter(results.results as any[], (country: OptionTypeBase) => {
                        return _.includes(country?.label?.toLowerCase(), filter?.toLowerCase());
                    }),
                    (country: any) => {
                        return country;
                    },
                );
            })
            .catch((err: any) => {
                showBanner({
                    message: 'Failed to get countries - ' + (err?.message ?? err),
                });
                return [];
            });
    };

    const checkEmailIsUnique = async (email: string): Promise<boolean> => {
        return organisationsRepository
            .post_action('check_email', undefined, { email: email })
            .then((results: any) => {
                return results.data.result;
            })
            .catch((err) => {
                return false;
            });
    };

    const validate = async (): Promise<boolean> => {
        let emailValid = true;

        const emailValidator = lookupValidator('email', 'text');
        emailValid = emailValidator.validate(newEmail ?? '');

        if (emailValid && newEmail && organisation?.email != newEmail) {
            emailValid = await checkEmailIsUnique(newEmail ?? '');
            if (!emailValid) {
                setError(
                    'email',
                    { type: 'validate', message: 'Email is already used by another contact' },
                    { shouldFocus: true },
                );
            }
        }

        const newValidationState = {
            email: emailValid,
        };
        return _.every(newValidationState);
    };

    const setCustomPropertyValue = (key: any, value: any) => {
        setValue(key, value);
    };

    return (
        <Sisp
            isOpen={shown}
            onCancel={() => {
                resetForm();
                setShown(false);
            }}
            validate={validate}
            footerOverride={
                <>
                    <PendingButton pending={submitting} type="submit" form="organisation_edit_form">
                        Save
                    </PendingButton>
                    <PendingButton
                        redirect={true}
                        pending={submitting}
                        type="submit"
                        form="organisation_edit_form"
                        variant="default"
                    >
                        Save & View
                    </PendingButton>
                </>
            }
        >
            <h4>Edit {organisation?.name}</h4>
            <Form id="organisation_edit_form" onSubmit={handleSubmit(onSubmitForm)}>
                <Form.Group>
                    <Form.Control type="hidden" {...register('id')} />
                </Form.Group>
                <Form.Group>
                    <Form.Control type="hidden" {...register('edu_data_package')} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control autoComplete="off" {...register('organisation_name')} />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Sector</Form.Label>
                    <DropDown
                        value={sector}
                        isInvalid={false}
                        isClearable={false}
                        menuPortalTarget={document.body}
                        classNamePrefix={'country-dropdown'}
                        onChange={(selected: OptionTypeBase) => {
                            setSector(selected);
                            setValue('organisation_sector', selected?.value ?? '');
                        }}
                        options={[
                            { value: 'Business', label: 'Business' },
                            { value: 'School', label: 'School' },
                        ]}
                        menuPosition="fixed"
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Label>{ucwords(dictionary['organisation'])} Type</Form.Label>
                    <Form.Control {...register('type_name')} />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Address</Form.Label>
                    <Form.Control {...register('address1')} placeholder={'Address 1'} />
                </Form.Group>
                <Form.Group>
                    <Form.Control {...register('address2')} placeholder={'Address 2'} />
                </Form.Group>
                <Form.Group>
                    <Form.Control {...register('address3')} placeholder={'Address 3'} />
                </Form.Group>
                <Form.Group>
                    <Form.Control {...register('address4')} placeholder={'Address 4'} />
                </Form.Group>
                <Form.Group>
                    <Form.Control {...register('county')} placeholder={'County'} />
                </Form.Group>
                <Form.Group>
                    <Form.Control {...register('region')} placeholder={'Region/State'} />
                </Form.Group>
                <Form.Group>
                    <Form.Control {...register('postcode')} placeholder={ucwords(dictionary['postcode'])} />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Country</Form.Label>
                    <AsyncDropDownPaginated
                        id={'country'}
                        value={country}
                        isInvalid={false}
                        isClearable={true}
                        menuPlacement="auto"
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        classNamePrefix={'country-dropdown'}
                        onChange={(selected: OptionTypeBase) => {
                            setCountry(selected);
                            setValue('country', selected?.value ?? '');
                        }}
                        loadOptions={async (filter: string, _loadedOptions, { page }) => {
                            const res = await loadCountries(filter, page);
                            return {
                                options: res,
                                hasMore: false,
                                additional: {
                                    page: page + 1,
                                },
                            };
                        }}
                    />
                </Form.Group>

                <Form.Group>
                    <Controller
                        control={control}
                        name={'owned_by'}
                        render={({ field: { value, onChange } }) => (
                            <OwnedByDropdown
                                label="Owned By"
                                onChange={onChange}
                                value={value}
                                defaultToYou={!value}
                                isClearable={false}
                            />
                        )}
                    />
                </Form.Group>

                <Form.Group className={errors.email ? 'has-error' : ''}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        autoComplete="off"
                        {...register('email', {
                            pattern: {
                                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                message: 'This email address is not valid.',
                            },
                        })}
                    />
                    {errors.email && <span className="help-block">{errors.email.message}</span>}
                </Form.Group>

                <Form.Group>
                    <Form.Label>Telephone</Form.Label>
                    <Form.Control {...register('telephone')} />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Website</Form.Label>
                    <Form.Control {...register('website')} />
                </Form.Group>

                {/* TODO CC-7546 */}
                {customTagsEnabled && tagsRolloutEnabled && (
                    <TagsEditDropdown
                        onChange={(tags) => {
                            setTags(tags);
                            setValue(
                                'tag_ids',
                                tags.map((v) => v.value),
                            );
                        }}
                        existingTags={tags}
                    />
                )}

                <CustomPropertyForm
                    propertyType={AvailablePropertyTypes.organisations}
                    customProperties={customProperties}
                    customPropertyValues={customPropertyValues}
                    updateFormPropertyState={setCustomPropertyValues}
                    setPropertyValue={setCustomPropertyValue}
                />
            </Form>
        </Sisp>
    );
};

export default OrganisationsEditSisp;
