import { ModulePermissions } from '@client/Applications/DataGrids/HelperFunctions/UserPermissionsHelper';
import { UserPermissionsContext } from '@client/Context/UserPermissions';
import {
    AppEvent,
    ClickableLink,
    EventBusInstance,
    filter as filterTypes,
    LogLevel,
    showBanner,
    Sisp,
} from '@sprint/sprint-react-components';
import { format, formatRelative, parse } from 'date-fns';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import { ucwords } from '../../../../Helpers/StringHelper';
import { customPropertyDataTypeMap } from '../../HelperFunctions/CustomPropertiesFiltersAndColumnsBuilder';
import { DictionaryContext } from '../../index';
import CustomPropertyFilterType from '../../Models/CustomPropertyFilterType';
import { HTMLColors } from '../../Models/Enums';
import TasksType, { UserDetails } from '../../Models/TasksType';
import './TasksSisps.scss';

interface Props {
    uniqueKey: string;
}

const TasksPreviewSisp: FunctionComponent<Props> = (props: Props) => {
    const dictionary = useContext(DictionaryContext);
    const userPermissions = useContext(UserPermissionsContext);

    const customProperties: any = JSON.parse(
        String((document.getElementById('custom-properties') as HTMLInputElement).value),
    );

    // State: General
    const [shown, setShown] = useState<boolean>(false);

    const [task, setTask] = useState<TasksType | null>(null);

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<TasksType>) => {
            if (event.target !== props.uniqueKey) return;
            setTask(event.message);
            setShown(true);
        });
    }, []);

    const prettifyUser = (user: UserDetails, extra?: string) => {
        return user ? (
            <span style={{ display: 'flex' }}>
                <img
                    src={user.avatar}
                    className={'img-rounded'}
                    style={{ height: '20px', marginRight: '8px' }}
                    alt={user.name}
                />
                <span>
                    {user.name}
                    {extra}
                </span>
            </span>
        ) : (
            <> </>
        );
    };

    const relativeDate = (date: string): string => {
        return formatRelative(parse(date, 'do, LLL yyyy h:mma', new Date()), new Date());
    };

    const getCustomPropertyDetails = (id: string): CustomPropertyFilterType | null => {
        const customPropertyDetails = customProperties.filter(
            (customProperty: CustomPropertyFilterType) => customProperty.key.split('-')[1] === id,
        );
        if (customPropertyDetails.length < 1) {
            showBanner({
                message: 'Failed to find Custom Property',
                level: LogLevel.ERROR,
                dismissable: false,
            });
            return null;
        }
        return customPropertyDetails[0];
    };

    const formatCustomPropertyValues = (id: string, value: string, data_type: string): any => {
        switch (customPropertyDataTypeMap.get(data_type)) {
            case filterTypes.FieldType.ENUM_ARRAY:
                const badges = value.split(/\r?\n/).map((item: any, i: number) => {
                    return (
                        <Badge style={{ marginRight: '3px' }} key={`custom-${id}-badge-${i}`}>
                            {item}
                        </Badge>
                    );
                });
                return <p className="side-panel">{badges}</p>;
            case filterTypes.FieldType.DATE:
                const date = new Date(value);
                return <p className="side-panel">{format(date, 'do, LLL yyyy')}</p>;
            default:
                return <p className="side-panel">{value}</p>;
        }
    };

    return (
        <Sisp
            className="tasks-preview"
            isOpen={shown}
            onCancel={() => setShown(false)}
            cancelButtonText="Close"
            footerOverride={
                <>
                    <Button
                        variant="primary"
                        onClick={(event) => {
                            event.preventDefault();
                            window.location.href = task!.name.url;
                        }}
                    >
                        View
                    </Button>
                    <Button variant="default" onClick={() => setShown(false)}>
                        Cancel
                    </Button>
                </>
            }
        >
            <>
                {task && (
                    <>
                        <h4>
                            {task.name.value}{' '}
                            {task.is_private && (
                                <small>
                                    <strong>(Private Task)</strong>
                                </small>
                            )}
                        </h4>
                        <small>
                            {task.modified_by.name} modified {relativeDate(task.modified_date)}
                        </small>
                        <div className="separator">
                            <p className="side-panel-small">Status</p>
                            <p className="side-panel">{task.status ?? 'No Status'}</p>
                        </div>
                        <div className="separator">
                            <p className="side-panel-small">Priority</p>
                            <p className="side-panel">{task.priority ?? 'No Priority'}</p>
                        </div>
                        {task.relates_to_contact && (
                            <div className="separator">
                                <p className="side-panel-small">Relates To Contact</p>
                                <p className="side-panel">
                                    <ClickableLink
                                        placeholder={task.relates_to_contact.name}
                                        url={task.relates_to_contact.url}
                                    />
                                </p>
                            </div>
                        )}
                        {task.relates_to_organisation && (
                            <div className="separator">
                                <p className="side-panel-small">Relates To {ucwords(dictionary['organisation'])}</p>
                                <p className="side-panel">
                                    <ClickableLink
                                        placeholder={task.relates_to_organisation.name}
                                        url={task.relates_to_organisation.url}
                                    />
                                </p>
                            </div>
                        )}
                        {task.relates_to_deal && (
                            <div className="separator">
                                <p className="side-panel-small">Relates To Deal</p>
                                <p className="side-panel">
                                    <ClickableLink
                                        placeholder={task.relates_to_deal.name}
                                        url={task.relates_to_deal.url}
                                    />
                                </p>
                            </div>
                        )}
                        {!(task.relates_to_contact || task.relates_to_organisation || task.relates_to_deal) && (
                            <div className="separator">
                                <p className="side-panel-small">Relates To</p>
                                <p className="side-panel">No Relations</p>
                            </div>
                        )}
                        <div className="separator">
                            <p className="side-panel-small">Due By</p>
                            <p
                                className="side-panel"
                                style={{ color: task.due_date.is_overdue ? HTMLColors.DANGER : 'inherit' }}
                            >
                                {task.due_date.date
                                    ? `${task.due_date.date} - ${relativeDate(task.due_date.date)}`
                                    : 'No Due Date'}
                            </p>
                        </div>
                        <div className="separator">
                            <p className="side-panel-small">Completed On</p>
                            <p className="side-panel">{task.completed_date ?? 'Not Completed Yet'}</p>
                        </div>
                        <div className="separator">
                            <p className="side-panel-small">Type</p>
                            <p className="side-panel">{task.type ?? 'No Type'}</p>
                        </div>
                        <div className="separator">
                            <p className="side-panel-small">Assigned To</p>
                            <p className="side-panel">{prettifyUser(task.assigned_to)}</p>
                        </div>
                        <div className="separator">
                            <p className="side-panel-small">Description</p>
                            <p className="side-panel">{task.description ?? 'No Description'}</p>
                        </div>
                        {userPermissions.customPropertiesTasks === ModulePermissions.ENABLED &&
                            task.custom_properties &&
                            Object.entries(task.custom_properties).map(([key, value]) => {
                                const customPropertyDetails = getCustomPropertyDetails(key);
                                if (customPropertyDetails) {
                                    return (
                                        <div className="separator" key={`custom-${key}`}>
                                            <p className="side-panel-small">{customPropertyDetails.name}</p>
                                            {formatCustomPropertyValues(key, value, customPropertyDetails.data_type)}
                                        </div>
                                    );
                                }
                            })}
                        <div className="separator">
                            <p className="side-panel-small">Assigned By</p>
                            <p className="side-panel">{prettifyUser(task.assigned_by)}</p>
                        </div>
                        <div className="separator">
                            <p className="side-panel-small">Created By</p>
                            <p className="side-panel">{prettifyUser(task.created_by, `, on ${task.created_date}`)}</p>
                        </div>
                    </>
                )}
            </>
        </Sisp>
    );
};

export default TasksPreviewSisp;
