import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import BulkEditSingleFieldModal, { Field } from '../../../../CommonComponents/BulkEdit/BulkEditSingleFieldModal';
import { toFields, useCustomProperties } from '../../../../CommonComponents/BulkEdit/CustomPropertyHelpers';
import BulkUpdateRepository, { UpdateType } from '../../Api/BulkEditRepository';
import Organisation from '../../Models/Organisation';

interface Props {
    rows: Organisation[];
    close: () => void;
}

const OrganisationsBulkEdit = (props: Props) => {
    const type: UpdateType = 'organisations';

    const bulkUpdateRepository = new BulkUpdateRepository();

    const [ids, setIds] = useState<number[]>([]);
    const [fields, setFields] = useState<Field[]>([]);
    const [customPropertyFields, setCustomPropertyFields] = useState<Field[]>([]);

    const customProperties = useCustomProperties(type);

    const getFields = (): Field[] => [
        {
            key: 'organisation_type',
            name: 'Sector',
            type: 'enum',
            loadOptions: async (_filter: string, _loadedOptions, { page }) => {
                return {
                    options: [
                        { value: 'school', label: 'School' },
                        { value: 'business', label: 'Business' },
                    ],
                    hasMore: false,
                    additional: {
                        page: page + 1,
                    },
                };
            },
            save: async (data: Record<string, any>) => {
                await bulkUpdateRepository.update({
                    type,
                    field: 'organisation_type',
                    value: data['organisation_type'].value,
                    ids,
                });
                return;
            },
        },
        {
            key: 'type_name',
            name: 'Business Type',
            type: 'string',
            save: async (data: Record<string, any>) => {
                await bulkUpdateRepository.update({
                    type,
                    field: 'type_name',
                    value: data['type_name'],
                    ids,
                });
                return;
            },
        },
        {
            key: 'owned_by',
            name: 'Owned By',
            type: 'owner',
            save: async (data: Record<string, any>) => {
                await bulkUpdateRepository.update({
                    type,
                    field: 'owned_by',
                    value: data['owned_by'].id,
                    ids,
                });
                return;
            },
        },
    ];

    useEffect(() => {
        setIds(_.map(props.rows, (row) => row.id));
        setFields(getFields());
    }, []);

    useEffect(() => {
        if (customProperties && ids.length) {
            setCustomPropertyFields(toFields(type, customProperties, bulkUpdateRepository, ids));
        }
    }, [ids, customProperties]);

    useEffect(() => {
        if (customPropertyFields.length) {
            setFields([...getFields(), ...customPropertyFields]);
        }
    }, [customPropertyFields]);

    return <BulkEditSingleFieldModal fields={fields} close={props.close} />;
};

export default OrganisationsBulkEdit;
