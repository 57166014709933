import { ModulePermissions } from '@client/Applications/DataGrids/HelperFunctions/UserPermissionsHelper';
import { UserPermissionsContext } from '@client/Context/UserPermissions';
import {
    ClickableLink,
    ExtendedColumn,
    HoveroverButton,
    LogLevel,
    NonSelectableRow,
    showBanner,
    StyledPill,
    User,
} from '@sprint/sprint-react-components';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CampaignsRequest } from '../../Api/CampaignsRequest';
import { KnowledgeBaseUrlKey, KnowledgeBaseUrls } from '../../HelperFunctions/KnowledgeBaseUrls';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import UserPermissionsHelper from '../../HelperFunctions/UserPermissionsHelper';
import { RepositoryFactoryContext, UserContext } from '../../index';
import CampaignType from '../../Models/CampaignType';
import { CampaignStatus, HTMLColors, UniqueKeyType } from '../../Models/Enums';
import CampusDataGrid, {
    ActionBarMeta,
    AddSispMeta,
    DataGridMeta,
    DeleteModalMeta,
    PromptMeta,
} from '../CampusDataGrid';
import ClientTagsColumn from '../ClientTags/ClientTagsColumn';
import CampaignsAddRedirect from './CampaignsAddRedirect';
import CampaignsCancelModal from './CampaignsCancelModal';
import CampaignsDeleteModal from './CampaignsDeleteModal';
import CampaignsEditSisp from './CampaignsEditSisp';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
    onlyShowCampaignsWithNoReport?: boolean;
    /** CC-7107 Take this flag out when feature is fully released */
    archivedOnly?: boolean;
}

enum CampaignsColumnKey {
    ID,
    EMAIL,
    LIST,
    TYPE,
    SCHEDULED,
    STATUS,
    SENT,
    OPENS,
    CLICKS,
}

const CampaignsTable: FunctionComponent<Props> = (props: Props) => {
    const editSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.EDIT_SISP);

    const campaignsRepository = useContext(RepositoryFactoryContext).getApiRepository(new CampaignsRequest());

    const businessDetailsCompleted = JSON.parse(
        String((document.getElementById('business_details_completed') as HTMLInputElement).value),
    );
    const permissions = useContext(UserPermissionsContext);
    const user: User = useContext(UserContext);

    // State: General
    const [reload, setReload] = useState<boolean>(false);

    useEffect(() => {
        if (reload) setReload(false);
    }, [reload]);

    // State: Cancel Campaign Modal
    const [cancelModalShown, setCancelModalShown] = useState<boolean>(false);
    const [cancelCampaignId, setCancelCampaignId] = useState<number>();

    // State: Columns
    const Columns: Record<CampaignsColumnKey, ExtendedColumn> = {
        [CampaignsColumnKey.ID]: {
            key: 'id',
            name: 'ID',
            sortable: false,
        },
        [CampaignsColumnKey.EMAIL]: {
            key: 'email',
            name: 'Email',
            sortable: true,
            width: '6fr',
            renderCell: (formatterProps) => {
                const id = (formatterProps.row as CampaignType).id;
                const email = (formatterProps.row as CampaignType).email;
                const status = (formatterProps.row as CampaignType).status;

                const handleCancelButtonClick = () => {
                    setCancelCampaignId(id);
                    setCancelModalShown(true);
                };

                return (
                    <>
                        {!props.onlyShowCampaignsWithNoReport && status === CampaignStatus.SENT ? (
                            <ClickableLink placeholder={email.value} url={email.url} />
                        ) : (
                            <> {email.value} </>
                        )}
                        <HoveroverButton
                            contents="Edit"
                            showHoverover={status === CampaignStatus.SCHEDULED}
                            eventBusMessageTarget={editSispUniqueKey}
                            eventBusMessage={formatterProps.row as CampaignType}
                        />
                        <HoveroverButton
                            contents="Cancel"
                            showHoverover={status === CampaignStatus.SCHEDULED}
                            handleClick={handleCancelButtonClick}
                        />
                    </>
                );
            },
        },
        [CampaignsColumnKey.LIST]: {
            key: 'list',
            name: 'List',
            sortable: true,
            width: '3fr',
            renderCell: (props) => {
                const list = (props.row as CampaignType).list;
                return <ClickableLink placeholder={list.value} url={list.url} />;
            },
        },
        [CampaignsColumnKey.TYPE]: {
            key: 'type',
            name: 'Type',
            sortable: true,
            width: '1.75fr',
        },
        [CampaignsColumnKey.SCHEDULED]: {
            key: 'scheduled',
            name: 'Scheduled',
            sortable: true,
            width: '2fr',
        },
        [CampaignsColumnKey.STATUS]: {
            key: 'status',
            name: 'Status',
            sortable: false,
            width: '1.15fr',
            renderCell: (formatterProps) => {
                const status = (formatterProps.row as CampaignType).status;

                if (props.onlyShowCampaignsWithNoReport && status == CampaignStatus.SENT) {
                    return (
                        <OverlayTrigger
                            overlay={
                                <Tooltip id="builtin-tooltip">
                                    This campaign has sent. A report is being processed and will soon be available in
                                    the Reporting tab.
                                </Tooltip>
                            }
                            trigger={['hover', 'focus']}
                        >
                            <span style={{ display: 'inline-block' }}>
                                <StyledPill
                                    cellContent={CampaignStatus.AWAITING_REPORT}
                                    style={{ backgroundColor: HTMLColors.SUCCESS }}
                                />
                            </span>
                        </OverlayTrigger>
                    );
                }

                const findPillStyle = (status: string): string => {
                    switch (status) {
                        case CampaignStatus.SENT:
                            return HTMLColors.SUCCESS;
                        case CampaignStatus.SCHEDULED:
                            return HTMLColors.PRIMARY;
                        default:
                            return HTMLColors.WARNING;
                    }
                };
                return <StyledPill cellContent={status} style={{ backgroundColor: findPillStyle(status) }} />;
            },
        },
        [CampaignsColumnKey.SENT]: {
            key: 'sent',
            name: 'Sent',
            sortable: false,
            width: '0.7fr',
        },
        [CampaignsColumnKey.OPENS]: {
            key: 'opens',
            name: 'Opens',
            sortable: false,
            width: '1.25fr',
            renderCell: (formatterProps) => {
                const data = formatterProps.row as CampaignType;
                return data.status === CampaignStatus.SENT ? data.opens : undefined;
            },
        },
        [CampaignsColumnKey.CLICKS]: {
            key: 'clicks',
            name: 'Clicks',
            sortable: false,
            width: '1.25fr',
            renderCell: (formatterProps) => {
                const data = formatterProps.row as CampaignType;
                return data.status === CampaignStatus.SENT ? data.clicks : undefined;
            },
        },
    };

    const DefaultColumns: ExtendedColumn[] = [
        Columns[CampaignsColumnKey.EMAIL],
        Columns[CampaignsColumnKey.LIST],
        Columns[CampaignsColumnKey.TYPE],
        Columns[CampaignsColumnKey.SCHEDULED],
        Columns[CampaignsColumnKey.STATUS],
        Columns[CampaignsColumnKey.SENT],
        Columns[CampaignsColumnKey.OPENS],
        Columns[CampaignsColumnKey.CLICKS],
    ];

    // DataGridActionBar
    const cancelCampaign = (id: number): Promise<boolean> => {
        return campaignsRepository
            .post_action('cancel_campaign', id)
            .then((success: any) => {
                showBanner({
                    message: success.data.message,
                    level: LogLevel.SUCCESS,
                });
                setReload(true);
                return true;
            })
            .catch((err: any) => {
                showBanner({
                    message: err?.message ?? err,
                    level: LogLevel.ERROR,
                });
                return false;
            });
    };

    const findNonSelectableRows = (rows: any): NonSelectableRow[] | null => {
        return rows
            ?.filter((row: any) => (row as CampaignType).status === CampaignStatus.SENDING)
            .map((row: any) => ({ id: row.id, reason: 'In the process of sending' }));
    };

    const externalParams: any = {};

    if (props.onlyShowCampaignsWithNoReport) {
        externalParams.onlyCampaignsWithNoReport = props.onlyShowCampaignsWithNoReport;
        if (permissions.customTags == ModulePermissions.ENABLED) {
            DefaultColumns.push(ClientTagsColumn);
        }
    }

    if (props.archivedOnly) {
        externalParams.archivedOnly = props.archivedOnly;
    }

    const dataGridMeta: DataGridMeta = {
        uniqueKey: props.dataGridUniqueKey,
        entitySingular: props.dataGridEntitySingular,
        entityPlural: props.dataGridEntityPlural,
        columnOptions: Columns,
        defaultColumns: DefaultColumns,
        frozenColumns: [],
        defaultSortColumn: 'id',
        forceReload: reload,
        nonSelectableRows: findNonSelectableRows,
        externalExtendedParameters: externalParams,
    };

    const actionBarMeta: ActionBarMeta = {
        searchPlaceHolder: props.searchFilterPlaceholder,
        includeCounts: true,
    };

    const addSispMeta: AddSispMeta = {
        key: UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.ADD_SISP),
        sisp: CampaignsAddRedirect,
    };

    const deleteModalMeta: DeleteModalMeta = {
        modal: CampaignsDeleteModal,
    };

    const promptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/no_campaigns.png',
        iconHeight: 180,
        helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.EMAILS),
    };

    const businessDetailsNeededAllowedPromptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/no_emails.png',
        iconHeight: 180,
        helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.CREATE_EMAIL),
        header: "You Can't Send Emails Yet",
        addLineText: 'To start sending emails some business settings need adding',
        addButtonLabel: 'Add Business Details',
        addOnClickOverride: () => {
            window.location.href = '/settings/business';
        },
    };

    const businessDetailsNeededForbiddenPromptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/no_emails.png',
        iconHeight: 180,
        helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.CREATE_EMAIL),
        header: "You Can't Send Emails Yet",
        additionalSubtext: [
            'To start sending emails some business settings need adding to Campus.',
            "Unfortunately your user level doesn't allow you to do this. You'll need to ask an Admin or Manager user to add them first.",
        ],
        hideAdd: true,
    };

    const returnCorrectBusinessDetailsNeededPrompt = (): PromptMeta => {
        return UserPermissionsHelper.isUserAnAdminOrManager(user)
            ? businessDetailsNeededAllowedPromptMeta
            : businessDetailsNeededForbiddenPromptMeta;
    };

    return (
        <>
            {cancelCampaignId && (
                <CampaignsCancelModal
                    modalShown={cancelModalShown}
                    cancelId={cancelCampaignId}
                    closeModal={() => setCancelModalShown(false)}
                    onSubmit={cancelCampaign}
                />
            )}
            <CampusDataGrid
                repository={campaignsRepository}
                actionBarMeta={actionBarMeta}
                addSispMeta={addSispMeta}
                editSispMeta={{ sisp: CampaignsEditSisp }}
                dataGridMeta={dataGridMeta}
                deleteModalMeta={deleteModalMeta}
                promptMeta={businessDetailsCompleted ? promptMeta : returnCorrectBusinessDetailsNeededPrompt()}
            />
        </>
    );
};

export default CampaignsTable;
