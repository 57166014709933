import { ModulePermissions } from '@client/Applications/DataGrids/HelperFunctions/UserPermissionsHelper';
import { UserPermissionsContext } from '@client/Context/UserPermissions';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import ClientGroupsTable from '../Grids/ClientGroups/ClientGroupsTable';
import { ClientGroupType } from '../Models/Enums';
import { UserContext } from '../index';
import './ViewsBar.scss';

interface Props {
    mainDataGridMeta: ClientGroupsMainDataGridMeta;
    clientGroupType: ClientGroupType;
    groupDataGridMeta?: ClientGroupsGroupsDataGridMeta;
    mainTabLabel?: string;
    groupTabLabel?: string;
}

export interface ClientGroupsMainDataGridMeta {
    dataGrid: React.FunctionComponent<any>;
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
    additionalProps?: Record<string, any>;
}

export interface ClientGroupsGroupsDataGridMeta {
    dataGrid?: React.FunctionComponent<any>;
    addSisp?: React.FunctionComponent<any>;
    editSisp?: React.FunctionComponent<any>;
    deleteModal?: React.FunctionComponent<any>;
    searchFilterPlaceholder?: string;
    dataGridEntitySingular?: string;
    dataGridEntityPlural?: string;
}

const clientGroupsTypePermissionsMap: Map<ClientGroupType, string> = new Map([
    [ClientGroupType.CUSTOM_PROPERTIES, 'clientGroupsCustomProperties'],
    [ClientGroupType.EMAILS, 'clientGroupsEmails'],
]);

const DualTabClientGroups: FunctionComponent<Props> = (props: Props) => {
    const user = useContext(UserContext);
    const userPermissions = useContext(UserPermissionsContext);
    const permissionKey = clientGroupsTypePermissionsMap.get(props.clientGroupType);
    const activeTabLsKey = `persistActiveTab-${props.mainDataGridMeta.dataGridUniqueKey}Group-${user?.id}`;
    const [activeTabId, setActiveTabId] = useState<number>();

    useEffect(() => {
        let activeTabId = 0;
        if (permissionKey && userPermissions[permissionKey] === ModulePermissions.ENABLED) {
            const activeTabLsId = localStorage.getItem(activeTabLsKey);
            if (activeTabLsId) {
                activeTabId = Number.parseInt(activeTabLsId);
            }
        }
        setActiveTabId(activeTabId);
    }, []);

    useEffect(() => {
        if (activeTabId !== undefined) {
            localStorage.setItem(activeTabLsKey, activeTabId.toString());
        }
    }, [activeTabId]);

    const onViewSelected = (tabId: number) => {
        if (permissionKey && userPermissions[permissionKey] === ModulePermissions.ENABLED) {
            setActiveTabId(tabId);
        }
    };

    const tabMaxWidth = 'calc((100% - 57px)';
    const mainTabLabel = props.mainTabLabel ?? props.mainDataGridMeta.dataGridEntityPlural;
    const GroupDataGridComponent = props.groupDataGridMeta?.dataGrid ?? ClientGroupsTable;

    return (
        <>
            {permissionKey && userPermissions[permissionKey] === ModulePermissions.ENABLED && (
                <div className="views-bar">
                    <ul className="nav nav-tabs view-tabs">
                        <li
                            className={`nav-item ${activeTabId === 0 ? 'active' : ''}`}
                            style={{ maxWidth: tabMaxWidth }}
                        >
                            <a className="nav-link" href="#" onClick={() => onViewSelected(0)}>
                                {mainTabLabel}
                            </a>
                        </li>
                        <li
                            className={`nav-item ${activeTabId === 1 ? 'active' : ''}`}
                            style={{ maxWidth: tabMaxWidth }}
                        >
                            <a className="nav-link" href="#" onClick={() => onViewSelected(1)}>
                                {props.groupTabLabel ?? props.groupDataGridMeta?.dataGridEntityPlural ?? 'Groups'}
                            </a>
                        </li>
                    </ul>
                </div>
            )}
            {activeTabId === 0 && (
                <props.mainDataGridMeta.dataGrid
                    searchFilterPlaceholder={props.mainDataGridMeta.searchFilterPlaceholder}
                    dataGridUniqueKey={props.mainDataGridMeta.dataGridUniqueKey}
                    dataGridEntitySingular={props.mainDataGridMeta.dataGridEntitySingular}
                    dataGridEntityPlural={props.mainDataGridMeta.dataGridEntityPlural}
                    {...props.mainDataGridMeta.additionalProps}
                />
            )}
            {activeTabId === 1 && (
                <GroupDataGridComponent
                    dataGridUniqueKey={props.mainDataGridMeta.dataGridUniqueKey + 'Groups'}
                    clientGroupType={props.clientGroupType}
                    mainDataGridEntitySingular={props.mainDataGridMeta.dataGridEntitySingular}
                    mainDataGridEntityPlural={props.mainDataGridMeta.dataGridEntityPlural}
                    addSisp={props.groupDataGridMeta?.addSisp}
                    editSisp={props.groupDataGridMeta?.editSisp}
                    deleteModal={props.groupDataGridMeta?.deleteModal}
                    mainTabLabel={mainTabLabel}
                    searchFilterPlaceholder={props.groupDataGridMeta?.searchFilterPlaceholder}
                    dataGridEntitySingular={props.groupDataGridMeta?.dataGridEntitySingular}
                    dataGridEntityPlural={props.groupDataGridMeta?.dataGridEntityPlural}
                />
            )}
        </>
    );
};

export default DualTabClientGroups;
