import { ExtendedColumn } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useContext } from 'react';
import UnsubscribedRequest from '../../Api/UnsubscribeRequests';
import { KnowledgeBaseUrlKey, KnowledgeBaseUrls } from '../../HelperFunctions/KnowledgeBaseUrls';
import { UnsubscribedType } from '../../Models/Enums';
import Unsubscribe from '../../Models/Unsubscribe';
import { RepositoryFactoryContext } from '../../index';
import CampusDataGrid, { ExportableExtendedColumn, PromptMeta } from '../CampusDataGrid';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
    type: string;
}

export enum UnsubscribesColumnKeys {
    ID,
    NAME,
    EMAIL,
    PACKAGE,
    UNSUBSCRIBED,
    REASON,
}

const UnsubscribedTable: FunctionComponent<Props> = (props: Props) => {
    const repository = useContext(RepositoryFactoryContext).getApiRepository(new UnsubscribedRequest(props.type));

    const Columns: Record<UnsubscribesColumnKeys, ExportableExtendedColumn> = {
        [UnsubscribesColumnKeys.ID]: {
            key: 'id',
            name: 'Id',
            sortable: false,
        },
        [UnsubscribesColumnKeys.NAME]: {
            key: 'name',
            name: 'Name',
            sortable: false,
            width: '3fr',
            renderCell: (props) => {
                const { name, school_id, package_url } = props.row as Unsubscribe;
                return school_id ? (
                    <a href={'/education/' + package_url + '/schools/' + school_id}>{name}</a>
                ) : (
                    <p>{name}</p>
                );
            },
            exportFormatter: (entity: any) => (entity as Unsubscribe)?.name || '',
        },
        [UnsubscribesColumnKeys.EMAIL]: {
            key: 'email',
            name: 'Email',
            sortable: false,
            width: '3fr',
            renderCell: (props) => {
                return <div dangerouslySetInnerHTML={{ __html: (props.row as Unsubscribe).email }}></div>;
            },
            exportFormatter: (entity: any) => '', // No point showing the email at all as its obfuscated
        },
        [UnsubscribesColumnKeys.PACKAGE]: {
            key: 'edu_data_package',
            name: 'Data Set',
            sortable: false,
            width: '1fr',
        },
        [UnsubscribesColumnKeys.UNSUBSCRIBED]: {
            key: 'unsubscribe_date',
            name: 'Unsubscribed',
            sortable: false,
            width: '1.5fr',
        },
        [UnsubscribesColumnKeys.REASON]: {
            key: 'unsubscribed_reason',
            name: 'Reason',
            sortable: false,
            width: '2fr',
        },
    };
    const DefaultColumns: ExtendedColumn[] = [
        Columns[UnsubscribesColumnKeys.NAME],
        Columns[UnsubscribesColumnKeys.EMAIL],
        Columns[UnsubscribesColumnKeys.PACKAGE],
        Columns[UnsubscribesColumnKeys.UNSUBSCRIBED],
        Columns[UnsubscribesColumnKeys.REASON],
    ];

    const generatePromptMeta = (unsubscribeType: string): PromptMeta => {
        let promptMeta: PromptMeta;
        switch (unsubscribeType) {
            case UnsubscribedType.TEACHERS:
                promptMeta = {
                    icon: '/assets/application/img/prompts/no_unsubscribed_schools.png',
                    entitySingular: 'Unsubscribed Teacher',
                    entityPlural: 'Unsubscribed Teachers',
                    iconHeight: 80,
                    helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.UNSUBSCRIBE_TEACHER),
                };
                break;
            case UnsubscribedType.SCHOOLS:
                promptMeta = {
                    icon: '/assets/application/img/prompts/no_unsubscribed_schools.png',
                    entitySingular: 'Unsubscribed School',
                    entityPlural: 'Unsubscribed Schools',
                    iconHeight: 80,
                    helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.UNSUBSCRIBE_SCHOOL),
                };
                break;
            default:
                throw new Error('unknown type {type}');
        }
        return promptMeta;
    };

    return (
        <>
            <CampusDataGrid
                repository={repository}
                dataGridMeta={{
                    uniqueKey: props.dataGridUniqueKey,
                    entitySingular: props.dataGridEntitySingular,
                    entityPlural: props.dataGridEntityPlural,
                    defaultColumns: DefaultColumns,
                    columnOptions: Columns,
                    hideSelect: true,
                    defaultSortColumn: 'unsubscribedDate',
                }}
                actionBarMeta={{
                    searchPlaceHolder: props.searchFilterPlaceholder,
                    includeCounts: true,
                    hideAddButton: true,
                    extraActionBarMeta: {
                        exportMeta: {
                            allowExport: true,
                            entity: 'Education_Unsubscribes',
                        },
                    },
                }}
                promptMeta={generatePromptMeta(props.type)}
            />
        </>
    );
};
export default UnsubscribedTable;
