import ClientTagsColumn from '@client/Applications/DataGrids/Grids/ClientTags/ClientTagsColumn';
import { ModulePermissions } from '@client/Applications/DataGrids/HelperFunctions/UserPermissionsHelper';
import { TagsRolloutEnabledContext } from '@client/Context/TagsRolloutEnabled';
import { UserPermissionsContext } from '@client/Context/UserPermissions';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    EntityTarget,
    ExtendedColumn,
    filter as filterTypes,
    HoveroverButton,
    OptionTypeBase,
    SearchQuery,
    SortOrder,
    ToolbarButton,
    ToolbarComponentProps,
    UserFormatter,
} from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { RenderCellProps } from 'react-data-grid';
import { Options } from 'react-select';
import { ucwords } from '../../../../Helpers/StringHelper';
import { DEFAULT_COLUMN_WIDTH, X_LARGE_COLUMN_WIDTH } from '../../../EducationDataGrid/constant';
import { EdukOfstedColumnsFactory } from '../../../EducationDataGrid/people/EdukOfstedColumns.generated';
import { OrganisationSavedViewsRequest } from '../../Api/OrganisationSavedViewsRequest';
import { OrganisationsRequest } from '../../Api/OrganisationsRequest';
import LearnMoreModal, { LearnMoreModalType } from '../../Components/LearnMoreModal';
import { SavedInboundView } from '../../Components/SavedInboundView';
import { SavedViewType } from '../../Components/ViewsModal';
import { buildCustomPropertiesFiltersAndColumns } from '../../HelperFunctions/CustomPropertiesFiltersAndColumnsBuilder';
import { KnowledgeBaseUrlKey, KnowledgeBaseUrls } from '../../HelperFunctions/KnowledgeBaseUrls';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import { DictionaryContext, RepositoryFactoryContext } from '../../index';
import { CustomPropertyType } from '../../Models/CustomPropertyType';
import { UniqueKeyType } from '../../Models/Enums';
import Organisation from '../../Models/Organisation';
import CampusDataGrid, {
    DeleteModalMeta,
    ExportableExtendedColumn,
    FilterExtendedColumn,
    PromptMeta,
} from '../CampusDataGrid';
import CampusDeleteModal from '../CampusDeleteModal';
import DataGridHelper from '../DataGridHelper';
import ApiFilterOptionsService from './filters/ApiFilterOptionsService';
import { SyncedDataFilterableFieldsEDIN } from './filters/SyncedDataFilterableFieldsEDIN';
import { SyncedDataFilterableFieldsEDUK } from './filters/SyncedDataFilterableFieldsEDUK';
import { SyncedDataFilterableFieldsEDUS } from './filters/SyncedDataFilterableFieldsEDUS';
import OrganisationsAddSisp from './OrganisationsAddSisp';
import OrganisationsBulkEdit from './OrganisationsBulkEdit';
import OrganisationsEditSisp from './OrganisationsEditSisp';
import OrganisationsPreviewSisp from './OrganisationsPreviewSisp';

export interface OrganisationsExtendedColumn extends FilterExtendedColumn, ExportableExtendedColumn {}

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
    customProperties: any;
    creationLocked: boolean;
    showAddSisp: boolean;
    activeViewId?: number;
}

export enum OrganisationsColumnKey {
    OWNER,
    UPDATED,
    ORGANISATION_FULL_ADDRESS,
    ORGANISATION_TOWN,
    ORGANISATION_COUNTY,
    ORGANISATION_REGION,
    ORGANISATION_POSTCODE,
    ORGANISATION_BUSINESS_TYPE,
    ORGANISATION_WEBSITE,
    ORGANISATION_EMAIL,
    ORGANISATION_TELEPHONE,
    ORGANISATION_COUNTRY,
    ORGANISATION_SECTOR,
    TAGS,
}

const OrganisationsTable: FunctionComponent<Props> = (props: Props) => {
    // Base Data
    const dictionary = useContext(DictionaryContext);
    const filterOptionsService = new ApiFilterOptionsService();

    const editSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.EDIT_SISP);
    const previewSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.PREVIEW_SISP);

    const tagsRolloutEnabled = useContext(TagsRolloutEnabledContext);

    // Repository Contexts
    const organisationsRepository = useContext(RepositoryFactoryContext).getApiRepository<Organisation>(
        new OrganisationsRequest(),
    );

    const permissions = useContext(UserPermissionsContext);
    const permissionFactory = (name: string): 'active' | 'invisible' | 'disabled' => {
        let ofstedPermission: 'active' | 'invisible' | 'disabled' = 'invisible';
        switch (permissions[name]) {
            case ModulePermissions.ENABLED:
                ofstedPermission = 'active';
                break;
            case ModulePermissions.DISABLED:
                ofstedPermission = 'disabled';
                break;
        }
        return ofstedPermission;
    };

    const canBulkEdit = permissions.bulkEditOrganisations === ModulePermissions.ENABLED;

    // Learn More
    const learnMoreRef = useRef<LearnMoreModalType | null>(null);
    const showLearnMore = (e: Event, label: string, summary: string, description: string) => {
        learnMoreRef.current?.displayComponent(e, label, summary, description);
    };
    const learnMoreModal = <LearnMoreModal ref={learnMoreRef} sisp={true} />;

    // Columns
    const ColumnsOptions: Record<OrganisationsColumnKey, OrganisationsExtendedColumn> = {
        [OrganisationsColumnKey.OWNER]: {
            key: 'owned_by',
            sortKey: 'o.owned_by',
            name: 'Owned By',
            sortable: true,
            renderCell: UserFormatter,
            minWidth: DEFAULT_COLUMN_WIDTH,
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const PAGE_SIZE = 100;
                const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'owned_by', SortOrder.ASC, filter);
                query.setExtendedParameters({
                    fieldname: 'owned_by',
                });
                return filterOptionsService.getFilterOptions(query);
            },
            exportFormatter: (entity: any) => (entity as Organisation)?.owned_by?.name || '',
        },
        [OrganisationsColumnKey.UPDATED]: {
            key: 'updated',
            sortKey: 'modified',
            name: 'Last Updated',
            cellClass: 'final-cell',
            headerCellClass: 'final-header-cell',
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            filterFieldType: filterTypes.FieldType.STRING,
        },
        [OrganisationsColumnKey.ORGANISATION_FULL_ADDRESS]: {
            key: 'full_address',
            name: 'Full Address',
            sortable: false,
            minWidth: DEFAULT_COLUMN_WIDTH,
        },
        [OrganisationsColumnKey.ORGANISATION_TOWN]: {
            key: 'address4',
            name: 'Town',
            sortable: true,
            minWidth: DEFAULT_COLUMN_WIDTH,
            filterFieldType: filterTypes.FieldType.STRING_ARRAY,
        },
        [OrganisationsColumnKey.ORGANISATION_COUNTY]: {
            key: 'county',
            name: 'County',
            sortable: true,
            minWidth: DEFAULT_COLUMN_WIDTH,
            filterFieldType: filterTypes.FieldType.STRING,
        },
        [OrganisationsColumnKey.ORGANISATION_REGION]: {
            key: 'region',
            name: 'Region',
            sortable: true,
            minWidth: DEFAULT_COLUMN_WIDTH,
            filterFieldType: filterTypes.FieldType.STRING,
        },
        [OrganisationsColumnKey.ORGANISATION_POSTCODE]: {
            key: 'postcode',
            name: ucwords(dictionary['postcode']),
            sortable: true,
            minWidth: DEFAULT_COLUMN_WIDTH,
            filterFieldType: filterTypes.FieldType.STRING,
        },
        [OrganisationsColumnKey.ORGANISATION_BUSINESS_TYPE]: {
            key: 'business_type',
            name: 'Business Type',
            filterFieldType: filterTypes.FieldType.STRING_ARRAY,
        },
        [OrganisationsColumnKey.ORGANISATION_WEBSITE]: {
            key: 'organisation_website',
            name: 'Website',
            filterFieldType: filterTypes.FieldType.STRING,
            renderCell: (props: RenderCellProps<unknown>) => {
                return <>{(props.row as Organisation).website}</>;
            },
        },
        [OrganisationsColumnKey.ORGANISATION_EMAIL]: {
            key: 'organisation_email',
            name: 'Email',
            filterFieldType: filterTypes.FieldType.STRING,
            renderCell: (props: RenderCellProps<unknown>) => {
                return <>{(props.row as Organisation).email}</>;
            },
            exportFormatter: (entity: any) => (entity as Organisation)?.email || '',
        },
        [OrganisationsColumnKey.ORGANISATION_TELEPHONE]: {
            key: 'organisation_telephone',
            name: 'Telephone',
            filterFieldType: filterTypes.FieldType.STRING,
            renderCell: (props: RenderCellProps<unknown>) => {
                return <>{(props.row as Organisation).telephone}</>;
            },
            exportFormatter: (entity: any) => (entity as Organisation)?.telephone || '',
        },
        [OrganisationsColumnKey.ORGANISATION_COUNTRY]: {
            key: 'country',
            name: 'Country',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const PAGE_SIZE = 100;
                const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'country', SortOrder.ASC, filter);
                query.setExtendedParameters({
                    fieldname: 'country',
                });
                return filterOptionsService.getFilterOptions(query);
            },
        },
        [OrganisationsColumnKey.ORGANISATION_SECTOR]: {
            key: 'organisation_type',
            name: 'Sector',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const PAGE_SIZE = 100;
                const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'organisation_type', SortOrder.ASC, filter);
                query.setExtendedParameters({
                    fieldname: 'organisation_type',
                });
                return filterOptionsService.getFilterOptions(query);
            },
        },
        [OrganisationsColumnKey.TAGS]: {
            ...ClientTagsColumn,
            filterFieldKey: 'organisation_tags',
            // TODO CC-7546
            permission: tagsRolloutEnabled ? ClientTagsColumn.permission : 'invisible',
        },
    };

    const DefaultColumns: OrganisationsExtendedColumn[] = [
        ColumnsOptions[OrganisationsColumnKey.ORGANISATION_TELEPHONE],
        ColumnsOptions[OrganisationsColumnKey.ORGANISATION_POSTCODE],
        ColumnsOptions[OrganisationsColumnKey.ORGANISATION_REGION],
        ColumnsOptions[OrganisationsColumnKey.OWNER],
        ColumnsOptions[OrganisationsColumnKey.UPDATED],
        ColumnsOptions[OrganisationsColumnKey.TAGS],
    ];

    const frozenColumns = [
        {
            key: 'name',
            sortKey: 'organisation_name',
            name: ucwords(dictionary['organisation']),
            sortable: true,
            renderCell: (props: RenderCellProps<unknown>) => {
                const org = (props.row as Organisation).name;
                const id = (props.row as Organisation).id;
                const editEventBusMessage: any = props.row as Organisation;
                return (
                    <>
                        <a href={'/subscribers/organisations/view/' + id}>{org}</a>
                        <HoveroverButton
                            contents="Preview"
                            showHoverover={true}
                            eventBusMessageTarget={previewSispUniqueKey}
                            eventBusMessage={editEventBusMessage}
                        />
                        <HoveroverButton
                            contents="Edit"
                            showHoverover={true}
                            eventBusMessageTarget={editSispUniqueKey}
                            eventBusMessage={editEventBusMessage}
                        />
                    </>
                );
            },
            exportFormatter: (entity: any) => (entity as Organisation)?.name || '',
            width: X_LARGE_COLUMN_WIDTH,
            filterFieldType: filterTypes.FieldType.STRING,
        },
    ];

    // Views
    const defaultView: SavedInboundView = {
        id: 0,
        name: 'All ' + ucwords(dictionary['organisations']),
        dataGridColumns: DefaultColumns.map((column: ExtendedColumn) => {
            return {
                key: column.key,
                width: DEFAULT_COLUMN_WIDTH,
            };
        }),
    };

    const [organisationCustomPropertyColumns, setOrganisationCustomPropertyColumns] =
        useState<Record<any, OrganisationsExtendedColumn>>();

    // On Did Mount
    // Set up custom property filters and columns
    useEffect(() => {
        const { columns, customProperties } = buildCustomPropertiesFiltersAndColumns(
            props.customProperties,
            CustomPropertyType.ORGANISATION,
        );

        setOrganisationCustomPropertyColumns(columns);
        setFilterableFieldsOrganisations([...filterableFieldsOrganisations, ...customProperties]);
    }, []);

    // Additional filters
    const salesFilters =
        permissions.moneyQuotesInListBuilder === ModulePermissions.ENABLED
            ? [
                  {
                      key: 'organisation_sales_count',
                      name: 'Number of Sales',
                      filterFieldType: filterTypes.FieldType.NUMBER,
                  },
                  {
                      key: 'organisation_sales_amount',
                      name: 'Value of Sales',
                      filterFieldType: filterTypes.FieldType.NUMBER,
                  },
                  {
                      key: 'organisation_quote_count',
                      name: 'Number of Quotes',
                      filterFieldType: filterTypes.FieldType.NUMBER,
                  },
                  {
                      key: 'organisation_quote_value',
                      name: 'Value of Quotes',
                      filterFieldType: filterTypes.FieldType.NUMBER,
                  },
                  {
                      key: 'organisation_sale_date',
                      name: 'Date of Sale',
                      filterFieldType: filterTypes.FieldType.DATE,
                  },
                  {
                      key: 'organisation_last_purchase_date',
                      name: 'Date of Last Sale',
                      filterFieldType: filterTypes.FieldType.DATE,
                  },
                  {
                      key: 'organisation_quote_date',
                      name: 'Date of Quote',
                      filterFieldType: filterTypes.FieldType.DATE,
                  },
                  {
                      key: 'organisation_last_quote_date',
                      name: 'Date of Last Quote',
                      filterFieldType: filterTypes.FieldType.DATE,
                  },
                  {
                      key: 'organisation_product_sale',
                      name: 'Product Sales',
                      filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
                      filterFieldAsyncOptions: (filter: string, page?: number) => {
                          const PAGE_SIZE = 100;
                          const query = new SearchQuery(
                              page ?? 1,
                              PAGE_SIZE,
                              'organisation_product_sale',
                              SortOrder.ASC,
                              filter,
                          );
                          query.setExtendedParameters({
                              fieldname: 'organisation_product_sale',
                          });
                          return filterOptionsService.getFilterOptions(query);
                      },
                  },
              ]
            : [];

    const dealsFilters =
        permissions.dealsInListBuilder === ModulePermissions.ENABLED
            ? [
                  {
                      key: 'organisation_deal_name',
                      name: 'Deal Name',
                      filterFieldType: filterTypes.FieldType.STRING,
                  },
                  {
                      key: 'organisation_deal_stage',
                      name: 'Deal Stage',
                      filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
                      filterFieldAsyncOptions: (filter: string, page?: number) => {
                          const PAGE_SIZE = 100;
                          const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'deal_stage', SortOrder.ASC, filter);
                          query.setExtendedParameters({
                              fieldname: 'deal_stage',
                          });
                          return filterOptionsService.getFilterOptions(query);
                      },
                  },
                  {
                      key: 'organisation_pipeline',
                      name: 'Deal Pipeline',
                      filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
                      filterFieldAsyncOptions: (filter: string, page?: number) => {
                          const PAGE_SIZE = 100;
                          const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'pipeline', SortOrder.ASC, filter);
                          query.setExtendedParameters({
                              fieldname: 'pipeline',
                          });
                          return filterOptionsService.getFilterOptions(query);
                      },
                  },
                  {
                      key: 'organisation_deals_value',
                      name: 'Value of Deals',
                      filterFieldType: filterTypes.FieldType.CURRENCY,
                  },
                  {
                      key: 'organisation_deal_owner',
                      name: 'Deal Owned By',
                      filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
                      filterFieldAsyncOptions: (filter: string, page?: number) => {
                          const PAGE_SIZE = 100;
                          const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'owned_by', SortOrder.ASC, filter);
                          query.setExtendedParameters({
                              fieldname: 'owned_by',
                          });
                          return filterOptionsService.getFilterOptions(query);
                      },
                  },
                  {
                      key: 'organisation_deal_type',
                      name: 'Deal Type',
                      filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
                      filterFieldAsyncOptions: (filter: string, page?: number) => {
                          const PAGE_SIZE = 100;
                          const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'deal_type', SortOrder.ASC, filter);
                          query.setExtendedParameters({
                              fieldname: 'deal_type',
                          });
                          return filterOptionsService.getFilterOptions(query);
                      },
                  },
                  {
                      key: 'organisation_deal_date_closed_by',
                      name: 'Deal Closed By Date',
                      filterFieldType: filterTypes.FieldType.DATE,
                  },
                  {
                      key: 'organisation_deal_date_follow_up',
                      name: 'Deal Follow Up Date',
                      filterFieldType: filterTypes.FieldType.DATE,
                  },
                  {
                      key: 'organisation_deal_first_created_date',
                      name: 'Deal First Created Date',
                      filterFieldType: filterTypes.FieldType.DATE,
                  },
                  {
                      key: 'organisation_deal_last_deal_closed_date',
                      name: 'Last Deal Closed Date',
                      filterFieldType: filterTypes.FieldType.DATE,
                  },
                  {
                      key: 'organisation_deal_last_deal_value',
                      name: 'Last Deal Value',
                      filterFieldType: filterTypes.FieldType.CURRENCY,
                  },
                  {
                      key: 'organisation_number_associated_deals',
                      name: 'Number of Associated Deals',
                      filterFieldType: filterTypes.FieldType.NUMBER,
                  },
              ]
            : [];

    // Main organisation filters
    const [filterableFieldsOrganisations, setFilterableFieldsOrganisations] = useState<FilterExtendedColumn[]>([
        {
            key: 'organisation_name',
            name: ucwords(dictionary['organisation']) + ' Name',
            filterFieldType: filterTypes.FieldType.STRING,
        },
        ColumnsOptions[OrganisationsColumnKey.ORGANISATION_BUSINESS_TYPE],
        ColumnsOptions[OrganisationsColumnKey.ORGANISATION_WEBSITE],
        ColumnsOptions[OrganisationsColumnKey.ORGANISATION_EMAIL],
        ColumnsOptions[OrganisationsColumnKey.ORGANISATION_TELEPHONE],
        ColumnsOptions[OrganisationsColumnKey.ORGANISATION_TOWN],
        ColumnsOptions[OrganisationsColumnKey.ORGANISATION_COUNTY],
        ColumnsOptions[OrganisationsColumnKey.ORGANISATION_REGION],
        ColumnsOptions[OrganisationsColumnKey.ORGANISATION_POSTCODE],
        ColumnsOptions[OrganisationsColumnKey.ORGANISATION_COUNTRY],
        ColumnsOptions[OrganisationsColumnKey.OWNER],
        ColumnsOptions[OrganisationsColumnKey.ORGANISATION_SECTOR],
        ColumnsOptions[OrganisationsColumnKey.TAGS],
        {
            key: 'organisation_converted_from_campaign',
            name: 'Email Campaign Converted From',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const PAGE_SIZE = 100;
                const query = new SearchQuery(
                    page ?? 1,
                    PAGE_SIZE,
                    'organisation_converted_from_campaign',
                    SortOrder.ASC,
                    filter,
                );
                query.setExtendedParameters({
                    fieldname: 'organisation_converted_from_campaign',
                });
                return filterOptionsService.getFilterOptions(query);
            },
        },
        {
            key: 'no_of_contacts',
            name: 'Number of Contacts',
            filterFieldType: filterTypes.FieldType.NUMBER,
        },
        {
            key: 'org_linked_edu_data',
            name: 'Linked Education Data',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const PAGE_SIZE = 100;
                const query = new SearchQuery(page ?? 1, PAGE_SIZE, 'org_linked_edu_data', SortOrder.ASC, filter);
                query.setExtendedParameters({
                    fieldname: 'org_linked_edu_data',
                });
                return filterOptionsService.getFilterOptions(query);
            },
        },
        // Append extra filters
        ...dealsFilters,
        ...salesFilters,
    ]);

    const filterSections: filterTypes.FieldFilterSection[] = [
        {
            key: 'secondaryFilters',
            name: ucwords(dictionary['organisation']) + ' Filters',
            filterableFields: _.map(
                _.filter(filterableFieldsOrganisations, (c) => !!c.filterFieldType),
                (c) => {
                    return DataGridHelper.columnToFilter(c);
                },
            ),
        },
    ];

    if (permissions.edukData == 'ENABLED' && permissions.edukInboundListFilters == 'ENABLED') {
        const filterableFields = _.map(
            _.filter(SyncedDataFilterableFieldsEDUK, (c) => !!c.filterFieldType),
            (c) => {
                return DataGridHelper.columnToFilter(c, undefined, 'eduk_');
            },
        );

        const ofstedColumns = EdukOfstedColumnsFactory(showLearnMore, permissionFactory);

        const filterableOfsted = _.map(Object.values(ofstedColumns), (c) => {
            return DataGridHelper.columnToFilter(c, undefined, 'eduk_');
        });
        filterableFields.push(...filterableOfsted);

        filterSections.push({
            key: 'edukEducationFilters',
            name: 'UK School Filters',
            filterableFields,
        });
    }

    if (permissions.edinData == 'ENABLED' && permissions.edinInboundListFilters == 'ENABLED') {
        filterSections.push({
            key: 'edinEducationFilters',
            name: 'International School Filters',
            filterableFields: _.map(
                _.filter(SyncedDataFilterableFieldsEDIN, (c) => !!c.filterFieldType),
                (c) => {
                    return DataGridHelper.columnToFilter(c, undefined, 'edin_');
                },
            ),
        });
    }

    if (permissions.edusData == 'ENABLED' && permissions.edusInboundListFilters == 'ENABLED') {
        filterSections.push({
            key: 'edusEducationFilters',
            name: 'US School Filters',
            filterableFields: _.map(
                _.filter(SyncedDataFilterableFieldsEDUS, (c) => !!c.filterFieldType),
                (c) => {
                    return DataGridHelper.columnToFilter(c, undefined, 'edus_');
                },
            ),
        });
    }

    // Edit Columns
    const getEditColumnOptions = (selected: Options<OptionTypeBase>): Options<OptionTypeBase> => {
        // Regular columns
        const availableOrganisationOptions = [];
        const selectedKeys = selected.map((option) => option.value);
        const keys = Object.keys(ColumnsOptions);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i] as unknown as OrganisationsColumnKey;
            if (!selectedKeys.includes(key)) {
                availableOrganisationOptions.push({
                    value: key,
                    label: (ColumnsOptions[key].name as string).replace(
                        'organisation',
                        ucwords(dictionary['organisation']),
                    ),
                    columnKey: ColumnsOptions[key].key,
                });
            }
        }

        // Custom properties
        if (organisationCustomPropertyColumns) {
            const customPropertyKeys = Object.keys(organisationCustomPropertyColumns);
            for (let i = 0; i < customPropertyKeys.length; i++) {
                const key = customPropertyKeys[i];
                if (!selectedKeys.includes(key)) {
                    availableOrganisationOptions.push({
                        value: key,
                        label: organisationCustomPropertyColumns[key].name as string,
                        columnKey: organisationCustomPropertyColumns[key].key,
                    });
                }
            }
        }

        // Combine
        return [
            { label: 'Selected', options: selected },
            { label: ucwords(dictionary['organisations']), options: availableOrganisationOptions },
        ];
    };

    const promptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/no_organisations.png',
        helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.ORGANISATIONS),
    };

    const deleteModalMeta: DeleteModalMeta = {
        modal: CampusDeleteModal,
    };

    const [selected, setSelected] = useState<Organisation[]>([]);
    const [showBulkEdit, setShowBulkEdit] = useState(false);
    const [reload, setReload] = useState(false);
    useEffect(() => {
        if (reload) {
            setReload(false);
        }
    }, [reload]);

    const SelectComponent: FunctionComponent<ToolbarComponentProps> = (props) => {
        return (
            <ToolbarButton
                buttonText="Edit Selected"
                onButtonPress={(
                    selectedRows: Set<React.Key>,
                    onComplete: () => void,
                    selectedRowData?: any[] | null,
                ) => {
                    setSelected(selectedRowData as Organisation[]);
                    setShowBulkEdit(true);
                }}
                {...props}
            />
        );
    };

    return (
        <>
            {showBulkEdit && (
                <OrganisationsBulkEdit
                    close={() => {
                        setShowBulkEdit(false);
                        setReload(true);
                    }}
                    rows={selected}
                />
            )}
            <CampusDataGrid
                repository={organisationsRepository}
                viewsMeta={{
                    request: new OrganisationSavedViewsRequest(),
                    entityTarget: EntityTarget.ORGANISATION,
                    displayStringSingular: 'List',
                    displayStringPlural: 'Lists',
                    savedViewType: SavedViewType.MARKETING_LIST,
                    defaultView: defaultView,
                    checkLimitDelegate: async () => {
                        const res = await fetch('/subscribers/lists/api/Organisation/check_list_limits');
                        if (res.ok) {
                            const json = await res.json();
                            return json.data;
                        } else {
                            throw new Error('Error fetching Education List limits');
                        }
                    },
                    limitReachedListName: `${ucwords(dictionary['organisation'])}`,
                    pinnedFirstTab: (
                        <OverlayTrigger
                            overlay={(overlayProps) => (
                                <Tooltip id="filter-tooltip" {...overlayProps}>
                                    {'All Contacts'}
                                </Tooltip>
                            )}
                            placement="right"
                        >
                            <a href={'/subscribers/contacts'}>
                                <FontAwesomeIcon icon={faUsers} />
                            </a>
                        </OverlayTrigger>
                    ),
                    forceLoadViewId: props.activeViewId,
                }}
                actionBarMeta={{
                    searchPlaceHolder: props.searchFilterPlaceholder,
                    includeCounts: true,
                    extraActionBarMeta: {
                        getEditColumnOptionsDelegate: getEditColumnOptions,
                        filterMeta: {
                            defaultActiveKey: 'secondaryFilters',
                            fieldFilterSections: filterSections,
                        },
                        exportMeta: {
                            allowExport: true,
                            entity: EntityTarget.ORGANISATION,
                        },
                    },
                }}
                addSispMeta={{
                    key: UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.ADD_SISP),
                    sisp: OrganisationsAddSisp,
                    showSisp: props.showAddSisp,
                }}
                editSispMeta={{ sisp: OrganisationsEditSisp }}
                previewSispMeta={{ sisp: OrganisationsPreviewSisp, key: previewSispUniqueKey }}
                dataGridMeta={{
                    uniqueKey: props.dataGridUniqueKey,
                    entitySingular: props.dataGridEntitySingular,
                    entityPlural: props.dataGridEntityPlural,
                    createButtonLocked: props.creationLocked,
                    columnOptions: {
                        ...ColumnsOptions,
                        ...organisationCustomPropertyColumns,
                    },
                    defaultColumns: DefaultColumns,
                    frozenColumns: frozenColumns,
                    draggableColumns: true,
                    defaultSortColumn: 'modified',
                    customSelectActionsComponent: canBulkEdit ? SelectComponent : undefined,
                    forceReload: reload,
                }}
                deleteModalMeta={deleteModalMeta}
                promptMeta={promptMeta}
                limitsModalMeta={{
                    limits: [{ limitApiKey: 'organisations_limit' }],
                }}
                learnMoreModal={learnMoreModal}
            />
        </>
    );
};

export default OrganisationsTable;
