import { ModulePermissions } from '@client/Applications/DataGrids/HelperFunctions/UserPermissionsHelper';
import { UserPermissionsContext } from '@client/Context/UserPermissions';
import { AppEvent, EventBusInstance, LogLevel, Sisp, showBanner } from '@sprint/sprint-react-components';
import {
    addMinutes,
    endOfDay,
    format,
    getHours,
    getMinutes,
    isPast,
    isSameDay,
    parse,
    roundToNearestMinutes,
    setHours,
    setMinutes,
} from 'date-fns';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import ClientTagPill from '../../../../CommonComponents/ClientTags/ClientTagPill';
import TagsEditDropdown from '../../../../CommonComponents/ClientTags/TagsEditDropdown';
import { CampaignsRequest } from '../../Api/CampaignsRequest';
import CampaignType from '../../Models/CampaignType';
import { RepositoryFactoryContext } from '../../index';
import './CampaignsSisps.scss';

interface ScheduleUpdate {
    schedule_date: string;
    schedule_time: string;
}

interface Props {
    uniqueKey: string;
    onSuccess: (event: any) => Promise<boolean>;
}

const CampaignsEditSisp: FunctionComponent<Props> = (props: Props) => {
    const campaignsRepository = useContext(RepositoryFactoryContext).getApiRepository(new CampaignsRequest());
    const permissions = useContext(UserPermissionsContext);
    const customTagsEnabled = permissions.customTags == ModulePermissions.ENABLED;

    // State: General
    const [shown, setShown] = useState<boolean>(false);
    const [scheduleValid, setScheduleValid] = useState<boolean>(true);
    const [rowId, setRowId] = useState<number>(0);

    // State: Datepicker
    const [currentDate, setCurrentDate] = useState<Date>();
    const [scheduledDate, setScheduledDate] = useState<Date>();

    // State: Tags
    const [tags, setTags] = useState<{ value: number; label: any }[]>();

    useEffect(() => {
        setScheduleValid(true);
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<CampaignType>) => {
            if (event.target !== props.uniqueKey) return;
            setRowId(event.message.id!);
            const currentScheduledDate: Date = parse(event.message.scheduled, 'do, LLL yyyy h:mma', new Date());
            setScheduledDate(currentScheduledDate);
            setCurrentDate(new Date());

            if (customTagsEnabled) {
                setTags(
                    event.message.tags?.map((t) => {
                        return {
                            value: t.id ?? 0,
                            label: <ClientTagPill tag={t} />,
                        };
                    }) ?? [],
                );
            }

            setShown(true);
        });
    }, [shown]);

    const handleUpdate = async (): Promise<boolean> => {
        const scheduleUpdate: ScheduleUpdate = {
            schedule_date: format(scheduledDate!, 'dd/MM/yyyy'),
            schedule_time: format(scheduledDate!, 'HH:mm'),
        };

        const scheduleUpdateSuccess = await campaignsRepository
            .post_action('update_schedule', rowId, scheduleUpdate)
            .then((results: any) => {
                props.onSuccess(results.data);
                showBanner({
                    message: 'Campaign scheduling has been successfully saved.',
                    level: LogLevel.SUCCESS,
                });
                return Promise.resolve(true);
            })
            .catch((err: any) => {
                showBanner({
                    message: err?.message ?? err,
                    level: LogLevel.ERROR,
                });
                return Promise.resolve(false);
            });

        let tagUpdateSuccess = true;
        if (customTagsEnabled && tags !== undefined) {
            tagUpdateSuccess = await campaignsRepository
                .post_action(
                    'update_tags',
                    rowId,
                    tags.map((v) => v.value),
                )
                .then((results: any) => {
                    props.onSuccess(results.data);
                    showBanner({
                        message: 'Campaign tags saved successfully.',
                        level: LogLevel.SUCCESS,
                    });
                    return Promise.resolve(true);
                })
                .catch((err: any) => {
                    showBanner({
                        message: err?.message ?? err,
                        level: LogLevel.ERROR,
                    });
                    return Promise.resolve(false);
                });
        }

        return Promise.resolve(scheduleUpdateSuccess && tagUpdateSuccess);
    };

    const validate = async (): Promise<boolean> => {
        const newScheduleValid: boolean = !isPast(scheduledDate!);
        if (!newScheduleValid) setCurrentDate(new Date());
        setScheduleValid(newScheduleValid);
        return newScheduleValid;
    };

    const addAndRoundMinutes = (date: Date, addAmount: number, roundAmount: number): Date => {
        let newDate: Date = addMinutes(date, addAmount);
        return roundToNearestMinutes(newDate, { nearestTo: roundAmount });
    };

    const handleDateUpdate = (date: Date) => {
        // If scheduled date is updated to the current day and the current
        // scheduled time is in the past then set the scheduled time to a
        // time in the future
        if (isSameDay(date, currentDate!) && isPast(date)) {
            const timeUpdate: Date = addAndRoundMinutes(currentDate!, 5, 5);
            date = setHours(date, getHours(timeUpdate));
            date = setMinutes(date, getMinutes(timeUpdate));
        }
        setScheduledDate(date);
    };

    const onSubmitForm = async (e: FormEvent) => {
        e.preventDefault();
        if ((await validate()) && (await handleUpdate())) setShown(false);
    };

    return (
        <Sisp
            isOpen={shown}
            onSubmit={handleUpdate}
            onCancel={() => {
                setShown(false);
            }}
            validate={validate}
        >
            <h4>Edit Campaign</h4>
            <Form onSubmit={onSubmitForm}>
                <Form.Group className="campaign-date">
                    <Form.Label>Send On This Date</Form.Label>
                    <DatePicker
                        className="campaigns-date-picker"
                        selected={scheduledDate}
                        onChange={handleDateUpdate}
                        dateFormat="dd/MM/yyyy"
                        minDate={currentDate}
                    />
                </Form.Group>
                <Form.Group className="campaign-time">
                    <Form.Label>At This Time</Form.Label>
                    <DatePicker
                        className="campaigns-date-picker"
                        selected={scheduledDate}
                        onChange={(date: Date) => setScheduledDate(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        minTime={
                            isSameDay(scheduledDate!, currentDate!) ? addAndRoundMinutes(currentDate!, 5, 5) : undefined
                        }
                        maxTime={isSameDay(scheduledDate!, currentDate!) ? endOfDay(currentDate!) : undefined}
                    />
                </Form.Group>
                <Form.Group>
                    {!scheduleValid && (
                        <Form.Control.Feedback type="invalid">
                            Sorry, please select a schedule in the future.
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
                {customTagsEnabled && (
                    <TagsEditDropdown
                        onChange={(tags) => {
                            setTags(tags);
                        }}
                        existingTags={tags}
                    />
                )}
            </Form>
        </Sisp>
    );
};

export default CampaignsEditSisp;
