'use strict';

import PropTypes from 'prop-types';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import Spinner from '../../../../CommonComponents/Spinner/Spinner';
import Deal from '../../../../Entities/Deal/Deal';

import '../../../../CommonComponents/Board/board.scss';

const keyCodes = {
    enter: 13,
    escape: 27,
    arrowDown: 40,
    arrowUp: 38,
    tab: 9,
};

class DealCard extends React.Component {
    previewActionClick(e, deal) {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.previewAction) this.props.previewAction(deal.id);
    }

    editActionClick(e, deal) {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.editAction) this.props.editAction(deal.id);
    }

    onKeyDown(event, provided, snapshot) {
        if (provided.dragHandleProps) {
            provided.dragHandleProps.onKeyDown(event);
        }

        if (event.defaultPrevented) return;
        if (snapshot.isDragging) return;
        if (event.keyCode !== keyCodes.enter) return;

        // Mark the event as used - we are using the event for selection
        event.preventDefault();

        this.performAction(event);
    }

    onClick(event) {
        if (event.defaultPrevented) return;
        if (event.target instanceof HTMLAnchorElement) return;
        // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
        const primaryButton = 0;
        if (event.button !== primaryButton) return;

        // Mark the event as used
        event.preventDefault();

        this.performAction(event);
    }

    onTouchEnd(event) {
        if (event.defaultPrevented) return;

        // Mark the event as used
        event.preventDefault();

        if (this.props.toggleSelectionInGroup) this.props.toggleSelectionInGroup(this.props.deal.id);
    }

    wasToggleInSelectionGroupKeyUsed(event) {
        // Determines if the platform specific toggle selection in group key was used
        const isUsingMac = navigator.platform.toUpperCase().indexOf('MAC') > -1;
        return isUsingMac ? event.metaKey : event.ctrlKey;
    }

    wasMultiSelectKeyUsed(event) {
        // Determines if the multiSelect key was used
        return event.shiftKey;
    }

    performAction(event) {
        if (this.wasToggleInSelectionGroupKeyUsed(event) || this.wasMultiSelectKeyUsed(event)) {
            event.stopPropagation();
            if (this.props.toggleSelectionInGroup) this.props.toggleSelectionInGroup(this.props.deal.id);
            return;
        }

        if (this.props.toggleSelection) this.props.toggleSelection(this.props.deal.id);

        this.previewActionClick(event, this.props.deal);
    }

    priceFormatted(deal) {
        if (!deal.value) return null;
        return deal.value.priceFormatted();
    }

    cardStyle(style, snapshot) {
        const dropping = snapshot.dropAnimation;
        if (!dropping) {
            return style;
        } else {
            const { curve, duration } = dropping;
            return {
                ...style,
                opacity: 0,
                transition: `all ${curve} ${duration + 0.2}s`, // slow down the drop
            };
        }
    }

    cardClassName(snapshot, props) {
        let className = 'react-board--card';

        if (snapshot.isDragging) className += ' dragging';
        else className += ' suspend-ordering'; // Deal state defines ordering, not user by dragging

        if (props.isDragDisabled) className += ' fixed';
        else if (props.isGhosting) className += ' ghosting';
        else if (props.deal.entityState.deleting) className += ' suspend-interactions ghosting';
        else if (props.isSelected) className += ' selected';
        return className;
    }

    render() {
        return (
            <Draggable
                draggableId={String(this.props.deal.id)}
                index={this.props.index}
                isDragDisabled={this.props.isDragDisabled}
            >
                {(provided, snapshot) => {
                    const shouldShowSelection = snapshot.isDragging && this.props.selectionCount > 1;

                    return (
                        <div
                            className={this.cardClassName(snapshot, this.props)}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={this.cardStyle(provided.draggableProps.style, snapshot)}
                            onClick={this.onClick.bind(this)}
                            onTouchEnd={this.onTouchEnd.bind(this)}
                            onKeyDown={(event) => this.onKeyDown(event, provided, snapshot)}
                        >
                            {this.props.deal.ownedBy && this.props.deal.ownedBy.avatar ? (
                                <span className="card--avatar">
                                    <img
                                        src={this.props.deal.ownedBy.avatar}
                                        alt=""
                                        className="avatar img-rounded"
                                        height="20"
                                    />
                                </span>
                            ) : null}
                            <span className="card--text">
                                <a href={`/deals/view/${this.props.deal.id}`}>{this.props.deal.name}</a>
                            </span>
                            <span className="card--addendum">
                                {this.props.deal.entityState.deleting ? (
                                    <Spinner inline={true} />
                                ) : (
                                    this.priceFormatted(this.props.deal)
                                )}
                            </span>
                            {shouldShowSelection ? (
                                <span className="card--selection-count">{this.props.selectionCount}</span>
                            ) : null}
                        </div>
                    );
                }}
            </Draggable>
        );
    }
}

DealCard.propTypes = {
    index: PropTypes.number.isRequired,
    deal: PropTypes.instanceOf(Deal).isRequired,
    isDragDisabled: PropTypes.bool,
    previewAction: PropTypes.func,
    editAction: PropTypes.func,
    isSelected: PropTypes.bool,
    isGhosting: PropTypes.bool,
    selectionCount: PropTypes.number,
    toggleSelection: PropTypes.func,
    toggleSelectionInGroup: PropTypes.func,
};

DealCard.defaultProps = {};

export default DealCard;
