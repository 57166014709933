import { UserPermissionsContext } from '@client/Context/UserPermissions';
import {
    AsyncDropDown,
    Checkbox,
    Modal,
    ModalType,
    OptionTypeBase,
    PendingButton,
    SearchQuery,
    showBanner,
} from '@sprint/sprint-react-components';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { RepositoryFactoryContext } from '../..';
import { QuoteClosedReasonOptionsRequest } from '../../Api/QuotesRequest';
import QuoteCloseReason from '../../Models/QuoteCloseReason';
import QuoteCloseRowsPayload from '../../Models/QuoteCloseRowsPayload';

interface Props {
    selectedRowsForDeletion: number[];
    dataGridEntityPlural: string;
    dataGridEntitySingular: string;
    modalShown: boolean;
    closeModal: () => void;
    onDeleteRows: (ids: number[]) => Promise<boolean>;
    onCloseRows: (payload: QuoteCloseRowsPayload) => Promise<boolean>;
}

const QuotesDeleteCloseModule: FunctionComponent<Props> = (props: Props) => {
    const quoteCloseReasonRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new QuoteClosedReasonOptionsRequest(),
    );
    const permissions = useContext(UserPermissionsContext);
    const quotesCloseable = permissions.quotesCloseable === 'ENABLED';

    const [closeQuotes, setCloseQuotes] = useState<boolean>(false);
    const [titleValue, setTitleValue] = useState<string>('Delete');

    const [closeReasonCollection, setCloseReasonCollection] = useState<QuoteCloseReason[]>([]);
    const [closeReason, setCloseReason] = useState<number | null>(null);
    const [closeReasonValid, setCloseReasonValid] = useState<boolean>(true);

    const [checkBoxLabel, setCheckboxLabel] = useState<string>('Delete funnel points associated with this quote');
    const [removeFunnelPoints, setRemoveFunnelPoints] = useState<boolean>(false);
    const [isDeleteButtonLoading, setIsDeleteButtonLoading] = useState<boolean>(false);
    const [isCloseButtonLoading, setIsCloseButtonLoading] = useState<boolean>(false);

    useEffect(() => {
        setTitleValue(closeQuotes ? 'Close' : 'Delete');
    }, [closeQuotes]);

    useEffect(() => {
        const label_value =
            props.selectedRowsForDeletion.length > 1
                ? `these ${props.selectedRowsForDeletion.length} ${props.dataGridEntityPlural.toLowerCase()}`
                : `this ${props.dataGridEntitySingular.toLowerCase()}`;
        setCheckboxLabel(`Delete funnel points associated with ${label_value}`);
    }, [props.selectedRowsForDeletion.length]);

    const reset = () => {
        setCloseQuotes(false);
        setTitleValue('Delete');
        setCloseReason(null);
        setCloseReasonValid(true);
        setCheckboxLabel('Delete funnel points associated with this quote');
        setRemoveFunnelPoints(false);
        setIsDeleteButtonLoading(false);
        setIsCloseButtonLoading(false);
    };

    const handleCloseQuotes = () => {
        if (closeQuotes) {
            setIsCloseButtonLoading(true);
            props
                .onCloseRows({
                    row_ids: props.selectedRowsForDeletion,
                    reason_id: closeReason!,
                    delete_funnel_points: removeFunnelPoints,
                })
                .catch((err) => {
                    showBanner({
                        message: 'Failed to close quote - ' + (err?.message ?? err),
                    });
                })
                .finally(() => {
                    handleModalClose();
                });
        } else {
            setCloseQuotes(true);
        }
    };

    const onGetCloseReasonOptions = async (filter: string) => {
        const query = new SearchQuery(1, 100);
        return quoteCloseReasonRepository
            .search(query)
            .then((results: any) => {
                if (results.wasCancelled) return results;
                setCloseReasonCollection(results.results);
                if (filter.length == 0) {
                    return results.results.map(dropdownMapLambda);
                } else {
                    return results.results
                        .filter((reason: QuoteCloseReason) =>
                            reason.reason.toLowerCase().includes(filter.toLowerCase()),
                        )
                        .map(dropdownMapLambda);
                }
            })
            .catch((err: any) => {
                return null;
            });
    };

    const dropdownMapLambda = (element: QuoteCloseReason) => {
        return {
            value: element.id,
            label: element.reason,
        };
    };

    const handleModalClose = () => {
        props.closeModal();
        reset();
    };

    return (
        <Modal
            isOpen={props.modalShown}
            type={ModalType.DELETE}
            title={
                props.selectedRowsForDeletion.length > 1
                    ? `${titleValue} These ${props.dataGridEntityPlural ?? 'Rows'}`
                    : `${titleValue} This ${props.dataGridEntitySingular ?? 'Row'}`
            }
            close={handleModalClose}
            footerOverride={
                <>
                    {!closeQuotes && (
                        <PendingButton
                            variant="danger"
                            onClick={() => {
                                setIsDeleteButtonLoading(true);
                                props
                                    .onDeleteRows(props.selectedRowsForDeletion)
                                    .catch((err) => {
                                        showBanner({
                                            message: 'Failed to delete quote - ' + (err?.message ?? err),
                                        });
                                    })
                                    .finally(() => {
                                        handleModalClose();
                                    });
                            }}
                            pending={isDeleteButtonLoading}
                        >
                            Delete{' '}
                            {props.selectedRowsForDeletion.length > 1
                                ? props.dataGridEntityPlural
                                : props.dataGridEntitySingular}
                        </PendingButton>
                    )}
                    {closeQuotes && (
                        <Button variant="default" onClick={() => setCloseQuotes(false)}>
                            Back
                        </Button>
                    )}
                    {quotesCloseable && (
                        <PendingButton variant="primary" onClick={handleCloseQuotes} pending={isCloseButtonLoading}>
                            Close{' '}
                            {props.selectedRowsForDeletion.length > 1
                                ? props.dataGridEntityPlural
                                : props.dataGridEntitySingular}
                        </PendingButton>
                    )}

                    <Button
                        variant="default"
                        onClick={() => {
                            handleModalClose();
                        }}
                    >
                        Close
                    </Button>
                </>
            }
        >
            <Form>
                {!closeQuotes && (
                    <Form.Group>
                        <Form.Label>
                            Are you sure that you want to delete{' '}
                            {props.selectedRowsForDeletion.length > 1
                                ? `these ${
                                      props.selectedRowsForDeletion.length
                                  } ${props.dataGridEntityPlural.toLowerCase()}`
                                : `this ${props.dataGridEntitySingular.toLowerCase()}`}{' '}
                            and any associated funnel points?
                        </Form.Label>
                        {quotesCloseable && (
                            <Form.Label>
                                Alternatively you could close{' '}
                                {props.selectedRowsForDeletion.length > 1
                                    ? `these ${
                                          props.selectedRowsForDeletion.length
                                      } ${props.dataGridEntityPlural.toLowerCase()}`
                                    : `this ${props.dataGridEntitySingular.toLowerCase()}`}{' '}
                                instead.
                            </Form.Label>
                        )}
                    </Form.Group>
                )}
                {quotesCloseable && closeQuotes && (
                    <>
                        <Form.Group>
                            <Form.Label>
                                Reason for closing{' '}
                                {props.selectedRowsForDeletion.length > 1
                                    ? `these ${
                                          props.selectedRowsForDeletion.length
                                      } ${props.dataGridEntityPlural.toLowerCase()}`
                                    : `this ${props.dataGridEntitySingular.toLowerCase()}`}{' '}
                                <span className="required-field-marker">*</span>
                            </Form.Label>
                            <AsyncDropDown
                                value={
                                    closeReason != null
                                        ? closeReasonCollection
                                              .map(dropdownMapLambda)
                                              .find((option) => option.value == closeReason)
                                        : null
                                }
                                isInvalid={false}
                                onChange={(option: OptionTypeBase) => {
                                    setCloseReason(option?.value ?? null);
                                    setCloseReasonValid(true);
                                }}
                                loadOptions={onGetCloseReasonOptions}
                                isClearable={true}
                                menuPortalTarget={document.body}
                            />
                            <Form.Control.Feedback type="invalid">
                                {!closeReasonValid && 'This field is required.'}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Checkbox
                                label={checkBoxLabel}
                                isChecked={removeFunnelPoints}
                                onChange={(event) => setRemoveFunnelPoints(event.target.checked)}
                            />
                        </Form.Group>
                    </>
                )}
            </Form>
        </Modal>
    );
};

export default QuotesDeleteCloseModule;
