import { isValidString, LogLevel, SearchQueryBuilder, showBanner, Sisp } from '@sprint/sprint-react-components';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Card, Form, Spinner } from 'react-bootstrap';
import { ProductRequest } from '../../Api/ProductRequest';
import { VatRateRequest } from '../../Api/VatRateRequest';
import { RepositoryFactoryContext } from '../../index';
import Product from '../../Models/Product';
import VatRate from '../../Models/VatRate';
import './ProductsSisps.scss';

interface Props {
    shown: boolean;
    onClose: () => void;
    onSuccess: (event: any) => Promise<boolean>;
}

const ProductsAddSisp: FunctionComponent<Props> = (props: Props) => {
    const productRepository = useContext(RepositoryFactoryContext).getApiRepository(new ProductRequest());
    const vatRateRepository = useContext(RepositoryFactoryContext).getApiRepository(new VatRateRequest());

    // State: General
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const focusRef = useRef<HTMLInputElement>(null);

    const [newProductName, setNewProductName] = useState<string>('');
    const [newProductNameValid, setNewProductNameValid] = useState<boolean>(true);

    const [newProductDescription, setNewProductDescription] = useState<string>('');

    const [newNetPrice, setNewNetPrice] = useState<string>('');
    const [newVatRate, setNewVatRate] = useState<string>('');
    const [vatRates, setVatRates] = useState<VatRate[]>();

    const getVatRates = (): Promise<boolean> => {
        return vatRateRepository
            .search(new SearchQueryBuilder().build())
            .then((results: any) => {
                setVatRates(
                    results.results.map((vatRate: VatRate) => {
                        if (vatRate.is_default) {
                            setNewVatRate(vatRate.vat_rate);
                        }
                        return vatRate;
                    }),
                );
                setIsLoading(false);
                return true;
            })
            .catch((err: any) => {
                showBanner({
                    message: 'Failed to get Vat Rates - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                    dismissable: false,
                });
                return false;
            });
    };

    useEffect(() => {
        if (props.shown) {
            setIsLoading(true);
            getVatRates();
        }
    }, [props.shown]);

    useEffect(() => {
        if (!isLoading) {
            focusRef.current?.focus();
        }
    }, [isLoading]);

    const validate = async (): Promise<boolean> => {
        const productNameValid = !!newProductName && isValidString(newProductName);
        setNewProductNameValid(productNameValid);
        return productNameValid;
    };

    const reset = () => {
        setNewProductName('');
        setNewProductNameValid(true);
        setNewProductDescription('');
        setNewNetPrice('');
        setNewVatRate('');
    };

    const handleAddRow = (): Promise<boolean> => {
        const Product: Product = {
            product_name: newProductName,
            product_description: newProductDescription,
            net_price: newNetPrice,
            vat_rate: newVatRate,
        };

        return productRepository
            .create(Product)
            .then(props.onSuccess)
            .then(async (success) => {
                reset();
                return success;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to create product - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return false;
            });
    };

    const onSubmitForm = async (e: FormEvent) => {
        e.preventDefault();
        if ((await validate()) && (await handleAddRow())) props.onClose();
    };

    return (
        <Sisp
            isOpen={props.shown}
            onSubmit={handleAddRow}
            onCancel={() => {
                reset();
                props.onClose();
            }}
            validate={validate}
        >
            <h4>Add a Product</h4>
            {isLoading ? (
                <div style={{ position: 'relative', alignItems: 'center' }}>
                    <Card
                        className="loading-spinner-container filter-loading-spinner"
                        style={{ background: '#f9f9f9' }}
                    >
                        <Spinner animation="border" role="status" />
                    </Card>
                </div>
            ) : (
                <Form onSubmit={onSubmitForm}>
                    <Form.Group>
                        <Form.Label>
                            Product <span className="required-field-marker">*</span>
                        </Form.Label>
                        <Form.Control
                            ref={focusRef}
                            type="text"
                            isInvalid={!newProductNameValid}
                            value={newProductName || ''}
                            onChange={(event) => {
                                setNewProductName(event.target.value);
                                setNewProductNameValid(true);
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            {!newProductNameValid && 'This field is required.'}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            type="text"
                            value={newProductDescription}
                            onChange={(event) => {
                                setNewProductDescription(event.target.value);
                            }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Price (£)</Form.Label>
                        <Form.Control
                            type="number"
                            value={newNetPrice}
                            onChange={(event) => {
                                setNewNetPrice(event.target.value);
                            }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Tax Rate</Form.Label>
                        {vatRates &&
                            vatRates.map((vatRate) => (
                                <Form.Check
                                    id={`vat-rate-add-${vatRate.id}`}
                                    name="vat-rate"
                                    type="radio"
                                    key={vatRate.id}
                                    label={`${vatRate.vat_rate}% ${vatRate.description}`}
                                    onChange={() => setNewVatRate(vatRate.vat_rate)}
                                    custom
                                    checked={newVatRate === vatRate.vat_rate}
                                />
                            ))}
                    </Form.Group>
                </Form>
            )}
        </Sisp>
    );
};

export default ProductsAddSisp;
