import { Context, createContext } from 'react';

export let UserPermissionsContext: Context<any>;
export let GlobalUserPermissions: any;
if (document.getElementById('user-permissions')) {
    const perms = JSON.parse(String((document.getElementById('user-permissions') as HTMLInputElement).value));
    if (perms) {
        UserPermissionsContext = createContext(perms);
        GlobalUserPermissions = perms;
    }
}
