/* eslint-disable quotes */
/**
 * This file was generated by a script and should not be changed manually.
 * Any modifications to this file may cause errors or inconsistencies.
 * It can be regenerated in flourish-and-blotts.
 */

import { ColumnGroup, filter as filterTypes } from '@sprint/sprint-react-components';
import _ from 'lodash';
import React from 'react';
import { DEFAULT_COLUMN_WIDTH } from '../constant';
import * as FilterGroups from '../helpers/FilterGroups';
import EdukPerson from '../models/eduk/EdukPerson';
import { PeopleExtendedColumn } from './PeopleTable';
import {
    IdaciQuintile,
    UngradedInspectionOverallOutcome,
    InspectionType,
    InspectionTypeGrouping,
    EventTypeGrouping,
    OverallEffectivenessScore,
    CategoryOfConcern,
    QualityOfEducationScore,
    BehaviourAndAttitudesScore,
    PersonalDevelopmentScore,
    EffectivenessOfLeadershipAndManagementScore,
    IsSafeguardingEffective,
    EarlyYearsProvisionScore,
    SixthFormProvisionScore,
    OverallEffectivenessPreviousScore,
    CategoryOfConcernPreviousScore,
    QualityOfEducationPreviousScore,
    BehaviourAndAttitudePreviousScore,
    PersonalDevelopmentPreviousScore,
    EffectivenessOfLeadershipAndManagementPreviousScore,
    IsSafeguardingEffectivePreviousScore,
    EarlyYearsProvisionPreviousScore,
    SixthFormProvisionPreviousScore,
    ofstedDescriptionFactory,
    ofstedLabelFactory,
    ofstedSummaryFactory,
} from '../models/eduk/EdukOfsted.generated';

export enum EdukOfstedColumnKey {
    IDACI_QUINTILE = 11000,
    NUMBER_UNGRADED_INSPECTIONS_SINCE_LAST_GRADED_INSPECTION,
    DATE_OF_LAST_UNGRADED_INSPECTION,
    UNGRADED_INSPECTION_PUBLICATION_DATE,
    DID_LATEST_UNGRADED_INSPECTION_CONVERT_TO_GRADED_INSPECTION,
    UNGRADED_INSPECTION_OVERALL_OUTCOME,
    NUMBER_OF_MONITORING_AND_URGENT_INSPECTIONS_SINCE_LAST_GRADED_INSPECTION,
    INSPECTION_TYPE,
    INSPECTION_TYPE_GROUPING,
    EVENT_TYPE_GROUPING,
    INSPECTION_START_DATE,
    INSPECTION_END_DATE,
    PUBLICATION_DATE,
    OVERALL_EFFECTIVENESS_SCORE,
    CATEGORY_OF_CONCERN,
    QUALITY_OF_EDUCATION_SCORE,
    BEHAVIOUR_AND_ATTITUDES_SCORE,
    PERSONAL_DEVELOPMENT_SCORE,
    EFFECTIVENESS_OF_LEADERSHIP_AND_MANAGEMENT_SCORE,
    IS_SAFEGUARDING_EFFECTIVE,
    EARLY_YEARS_PROVISION_SCORE,
    SIXTH_FORM_PROVISION_SCORE,
    PREVIOUS_INSPECTION_START_DATE,
    PREVIOUS_INSPECTION_END_DATE,
    PREVIOUS_PUBLICATION_DATE,
    OVERALL_EFFECTIVENESS_PREVIOUS_SCORE,
    CATEGORY_OF_CONCERN_PREVIOUS_SCORE,
    QUALITY_OF_EDUCATION_PREVIOUS_SCORE,
    BEHAVIOUR_AND_ATTITUDE_PREVIOUS_SCORE,
    PERSONAL_DEVELOPMENT_PREVIOUS_SCORE,
    EFFECTIVENESS_OF_LEADERSHIP_AND_MANAGEMENT_PREVIOUS_SCORE,
    IS_SAFEGUARDING_EFFECTIVE_PREVIOUS_SCORE,
    EARLY_YEARS_PROVISION_PREVIOUS_SCORE,
    SIXTH_FORM_PROVISION_PREVIOUS_SCORE,
}

export function EdukOfstedColumnsFactory(
    learnMore: (event: any, label: string, summary: string, description: string) => void,
    permissionFactory: (name: string) => 'active' | 'invisible' | 'disabled',
): Record<EdukOfstedColumnKey, PeopleExtendedColumn> {
    return {
        [EdukOfstedColumnKey.IDACI_QUINTILE]: {
            key: 'ofsted.idaciQuintile',
            name: 'IDACI Quintile',
            description: (
                <>
                    <p>IDACI (Income Deprivation Affecting Children Index 2019) is used to indicate deprivation</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            learnMore(
                                e,
                                ofstedLabelFactory('idaciQuintile')!,
                                ofstedSummaryFactory('idaciQuintile')!,
                                ofstedDescriptionFactory('idaciQuintile')!,
                            );
                        }}
                    >
                        Learn More
                    </a>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label = (props.row as EdukPerson)?.establishment?.ofsted?.idaciQuintile?.toString() || '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.ENUM,
            filterFieldOptions: () =>
                _.map(Object.values(IdaciQuintile), (value) => ({
                    value: value,
                    name: value,
                })),
            filterGroups: { [FilterGroups.SOCIOECONOMIC]: [] },
            permission: permissionFactory('edukOfstedIdaciQuintile'),
        },
        [EdukOfstedColumnKey.NUMBER_UNGRADED_INSPECTIONS_SINCE_LAST_GRADED_INSPECTION]: {
            key: 'ofsted.numberUngradedInspectionsSinceLastGradedInspection',
            name: 'No. Inspections Since Last Graded Inspection',
            description: (
                <>
                    <p>Number of ungraded inspections since the last graded inspection</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            learnMore(
                                e,
                                ofstedLabelFactory('numberUngradedInspectionsSinceLastGradedInspection')!,
                                ofstedSummaryFactory('numberUngradedInspectionsSinceLastGradedInspection')!,
                                ofstedDescriptionFactory('numberUngradedInspectionsSinceLastGradedInspection')!,
                            );
                        }}
                    >
                        Learn More
                    </a>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label =
                    (
                        props.row as EdukPerson
                    )?.establishment?.ofsted?.numberUngradedInspectionsSinceLastGradedInspection?.toString() || '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.NUMBER,
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Ungraded Inspection'] },
            permission: permissionFactory('edukOfstedNumberUngradedInspectionsSinceLastGradedInspection'),
        },
        [EdukOfstedColumnKey.DATE_OF_LAST_UNGRADED_INSPECTION]: {
            key: 'ofsted.dateOfLastUngradedInspection',
            name: 'Last Inspection Date',
            description: (
                <>
                    <p>Last date of ungraded inspection</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            learnMore(
                                e,
                                ofstedLabelFactory('dateOfLastUngradedInspection')!,
                                ofstedSummaryFactory('dateOfLastUngradedInspection')!,
                                ofstedDescriptionFactory('dateOfLastUngradedInspection')!,
                            );
                        }}
                    >
                        Learn More
                    </a>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label =
                    (props.row as EdukPerson)?.establishment?.ofsted?.dateOfLastUngradedInspection?.toString() || '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.DATE,
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Ungraded Inspection'] },
            permission: permissionFactory('edukOfstedDateOfLastUngradedInspection'),
        },
        [EdukOfstedColumnKey.UNGRADED_INSPECTION_PUBLICATION_DATE]: {
            key: 'ofsted.ungradedInspectionPublicationDate',
            name: 'Inspection Published Date',
            description: (
                <>
                    <p>Ungraded inspection publication date</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            learnMore(
                                e,
                                ofstedLabelFactory('ungradedInspectionPublicationDate')!,
                                ofstedSummaryFactory('ungradedInspectionPublicationDate')!,
                                ofstedDescriptionFactory('ungradedInspectionPublicationDate')!,
                            );
                        }}
                    >
                        Learn More
                    </a>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label =
                    (props.row as EdukPerson)?.establishment?.ofsted?.ungradedInspectionPublicationDate?.toString() ||
                    '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.DATE,
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Ungraded Inspection'] },
            permission: permissionFactory('edukOfstedUngradedInspectionPublicationDate'),
        },
        [EdukOfstedColumnKey.DID_LATEST_UNGRADED_INSPECTION_CONVERT_TO_GRADED_INSPECTION]: {
            key: 'ofsted.didLatestUngradedInspectionConvertToGradedInspection',
            name: 'Converted to Graded Inspection',
            description: (
                <>
                    <p>Whether the latest ungraded inspection converted into a graded inspection</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            learnMore(
                                e,
                                ofstedLabelFactory('didLatestUngradedInspectionConvertToGradedInspection')!,
                                ofstedSummaryFactory('didLatestUngradedInspectionConvertToGradedInspection')!,
                                ofstedDescriptionFactory('didLatestUngradedInspectionConvertToGradedInspection')!,
                            );
                        }}
                    >
                        Learn More
                    </a>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const value = (props.row as EdukPerson)?.establishment?.ofsted
                    ?.didLatestUngradedInspectionConvertToGradedInspection;
                const label = value == true ? 'Yes' : value == false ? 'No' : '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.BOOLEAN,
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Ungraded Inspection'] },
            permission: permissionFactory('edukOfstedDidLatestUngradedInspectionConvertToGradedInspection'),
        },
        [EdukOfstedColumnKey.UNGRADED_INSPECTION_OVERALL_OUTCOME]: {
            key: 'ofsted.ungradedInspectionOverallOutcome',
            name: 'Overall Outcome',
            description: (
                <>
                    <p>Overall outcome of the ungraded inspection</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            learnMore(
                                e,
                                ofstedLabelFactory('ungradedInspectionOverallOutcome')!,
                                ofstedSummaryFactory('ungradedInspectionOverallOutcome')!,
                                ofstedDescriptionFactory('ungradedInspectionOverallOutcome')!,
                            );
                        }}
                    >
                        Learn More
                    </a>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label =
                    (props.row as EdukPerson)?.establishment?.ofsted?.ungradedInspectionOverallOutcome?.toString() ||
                    '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.ENUM,
            filterFieldOptions: () =>
                _.map(Object.values(UngradedInspectionOverallOutcome), (value) => ({
                    value: value,
                    name: value,
                })),
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Ungraded Inspection'] },
            permission: permissionFactory('edukOfstedUngradedInspectionOverallOutcome'),
        },
        [EdukOfstedColumnKey.NUMBER_OF_MONITORING_AND_URGENT_INSPECTIONS_SINCE_LAST_GRADED_INSPECTION]: {
            key: 'ofsted.numberOfMonitoringAndUrgentInspectionsSinceLastGradedInspection',
            name: 'No. Monitoring/Urgent Inspections Since Last Graded Inspection',
            description: (
                <>
                    <p>Number of monitoring or urgent inspections since the last graded inspection</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            learnMore(
                                e,
                                ofstedLabelFactory('numberOfMonitoringAndUrgentInspectionsSinceLastGradedInspection')!,
                                ofstedSummaryFactory(
                                    'numberOfMonitoringAndUrgentInspectionsSinceLastGradedInspection',
                                )!,
                                ofstedDescriptionFactory(
                                    'numberOfMonitoringAndUrgentInspectionsSinceLastGradedInspection',
                                )!,
                            );
                        }}
                    >
                        Learn More
                    </a>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label =
                    (
                        props.row as EdukPerson
                    )?.establishment?.ofsted?.numberOfMonitoringAndUrgentInspectionsSinceLastGradedInspection?.toString() ||
                    '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.NUMBER,
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Ungraded Inspection'] },
            permission: permissionFactory('edukOfstedNumberOfMonitoringAndUrgentInspectionsSinceLastGradedInspection'),
        },
        [EdukOfstedColumnKey.INSPECTION_TYPE]: {
            key: 'ofsted.inspectionType',
            name: 'Inspection Type',
            description: (
                <>
                    <p>Type of inspection undertaken</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            learnMore(
                                e,
                                ofstedLabelFactory('inspectionType')!,
                                ofstedSummaryFactory('inspectionType')!,
                                ofstedDescriptionFactory('inspectionType')!,
                            );
                        }}
                    >
                        Learn More
                    </a>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label = (props.row as EdukPerson)?.establishment?.ofsted?.inspectionType?.toString() || '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.ENUM,
            filterFieldOptions: () =>
                _.map(Object.values(InspectionType), (value) => ({
                    value: value,
                    name: value,
                })),
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Latest Graded Inspection'] },
            permission: permissionFactory('edukOfstedInspectionType'),
        },
        [EdukOfstedColumnKey.INSPECTION_TYPE_GROUPING]: {
            key: 'ofsted.inspectionTypeGrouping',
            name: 'Inspection Type Grouping',
            description: (
                <>
                    <p>Inspection Type Grouping</p>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label =
                    (props.row as EdukPerson)?.establishment?.ofsted?.inspectionTypeGrouping?.toString() || '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.ENUM,
            filterFieldOptions: () =>
                _.map(Object.values(InspectionTypeGrouping), (value) => ({
                    value: value,
                    name: value,
                })),
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Latest Graded Inspection'] },
            permission: permissionFactory('edukOfstedInspectionTypeGrouping'),
        },
        [EdukOfstedColumnKey.EVENT_TYPE_GROUPING]: {
            key: 'ofsted.eventTypeGrouping',
            name: 'Event Type Grouping',
            description: (
                <>
                    <p>Event type grouping</p>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label = (props.row as EdukPerson)?.establishment?.ofsted?.eventTypeGrouping?.toString() || '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.ENUM,
            filterFieldOptions: () =>
                _.map(Object.values(EventTypeGrouping), (value) => ({
                    value: value,
                    name: value,
                })),
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Latest Graded Inspection'] },
            permission: permissionFactory('edukOfstedEventTypeGrouping'),
        },
        [EdukOfstedColumnKey.INSPECTION_START_DATE]: {
            key: 'ofsted.inspectionStartDate',
            name: 'Inspection Start Date',
            description: (
                <>
                    <p>Inspection start date</p>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label = (props.row as EdukPerson)?.establishment?.ofsted?.inspectionStartDate?.toString() || '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.DATE,
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Latest Graded Inspection'] },
            permission: permissionFactory('edukOfstedInspectionStartDate'),
        },
        [EdukOfstedColumnKey.INSPECTION_END_DATE]: {
            key: 'ofsted.inspectionEndDate',
            name: 'Inspection End Date',
            description: (
                <>
                    <p>Inspection end date</p>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label = (props.row as EdukPerson)?.establishment?.ofsted?.inspectionEndDate?.toString() || '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.DATE,
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Latest Graded Inspection'] },
            permission: permissionFactory('edukOfstedInspectionEndDate'),
        },
        [EdukOfstedColumnKey.PUBLICATION_DATE]: {
            key: 'ofsted.publicationDate',
            name: 'Publication Date',
            description: (
                <>
                    <p>Inspection publication date</p>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label = (props.row as EdukPerson)?.establishment?.ofsted?.publicationDate?.toString() || '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.DATE,
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Latest Graded Inspection'] },
            permission: permissionFactory('edukOfstedPublicationDate'),
        },
        [EdukOfstedColumnKey.OVERALL_EFFECTIVENESS_SCORE]: {
            key: 'ofsted.overallEffectivenessScore',
            name: 'Overall Effectiveness',
            description: (
                <>
                    <p>Overall effectiveness of the establishment</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            learnMore(
                                e,
                                ofstedLabelFactory('overallEffectivenessScore')!,
                                ofstedSummaryFactory('overallEffectivenessScore')!,
                                ofstedDescriptionFactory('overallEffectivenessScore')!,
                            );
                        }}
                    >
                        Learn More
                    </a>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label =
                    (props.row as EdukPerson)?.establishment?.ofsted?.overallEffectivenessScore?.toString() || '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.ENUM,
            filterFieldOptions: () =>
                _.map(Object.values(OverallEffectivenessScore), (value) => ({
                    value: value,
                    name: value,
                })),
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Latest Graded Inspection'] },
            permission: permissionFactory('edukOfstedOverallEffectivenessScore'),
        },
        [EdukOfstedColumnKey.CATEGORY_OF_CONCERN]: {
            key: 'ofsted.categoryOfConcern',
            name: 'Category of Concern',
            description: (
                <>
                    <p>Type of category of concern arising from an inadequate inspection</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            learnMore(
                                e,
                                ofstedLabelFactory('categoryOfConcern')!,
                                ofstedSummaryFactory('categoryOfConcern')!,
                                ofstedDescriptionFactory('categoryOfConcern')!,
                            );
                        }}
                    >
                        Learn More
                    </a>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label = (props.row as EdukPerson)?.establishment?.ofsted?.categoryOfConcern?.toString() || '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.ENUM,
            filterFieldOptions: () =>
                _.map(Object.values(CategoryOfConcern), (value) => ({
                    value: value,
                    name: value,
                })),
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Latest Graded Inspection'] },
            permission: permissionFactory('edukOfstedCategoryOfConcern'),
        },
        [EdukOfstedColumnKey.QUALITY_OF_EDUCATION_SCORE]: {
            key: 'ofsted.qualityOfEducationScore',
            name: 'Quality of Education',
            description: (
                <>
                    <p>Quality of education provided at the establishment</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            learnMore(
                                e,
                                ofstedLabelFactory('qualityOfEducationScore')!,
                                ofstedSummaryFactory('qualityOfEducationScore')!,
                                ofstedDescriptionFactory('qualityOfEducationScore')!,
                            );
                        }}
                    >
                        Learn More
                    </a>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label =
                    (props.row as EdukPerson)?.establishment?.ofsted?.qualityOfEducationScore?.toString() || '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.ENUM,
            filterFieldOptions: () =>
                _.map(Object.values(QualityOfEducationScore), (value) => ({
                    value: value,
                    name: value,
                })),
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Latest Graded Inspection'] },
            permission: permissionFactory('edukOfstedQualityOfEducationScore'),
        },
        [EdukOfstedColumnKey.BEHAVIOUR_AND_ATTITUDES_SCORE]: {
            key: 'ofsted.behaviourAndAttitudesScore',
            name: 'Behaviour and Attitudes',
            description: (
                <>
                    <p>Behaviour and attitudes at the establishment</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            learnMore(
                                e,
                                ofstedLabelFactory('behaviourAndAttitudesScore')!,
                                ofstedSummaryFactory('behaviourAndAttitudesScore')!,
                                ofstedDescriptionFactory('behaviourAndAttitudesScore')!,
                            );
                        }}
                    >
                        Learn More
                    </a>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label =
                    (props.row as EdukPerson)?.establishment?.ofsted?.behaviourAndAttitudesScore?.toString() || '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.ENUM,
            filterFieldOptions: () =>
                _.map(Object.values(BehaviourAndAttitudesScore), (value) => ({
                    value: value,
                    name: value,
                })),
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Latest Graded Inspection'] },
            permission: permissionFactory('edukOfstedBehaviourAndAttitudesScore'),
        },
        [EdukOfstedColumnKey.PERSONAL_DEVELOPMENT_SCORE]: {
            key: 'ofsted.personalDevelopmentScore',
            name: 'Personal Development',
            description: (
                <>
                    <p>Personal development at the establishment</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            learnMore(
                                e,
                                ofstedLabelFactory('personalDevelopmentScore')!,
                                ofstedSummaryFactory('personalDevelopmentScore')!,
                                ofstedDescriptionFactory('personalDevelopmentScore')!,
                            );
                        }}
                    >
                        Learn More
                    </a>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label =
                    (props.row as EdukPerson)?.establishment?.ofsted?.personalDevelopmentScore?.toString() || '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.ENUM,
            filterFieldOptions: () =>
                _.map(Object.values(PersonalDevelopmentScore), (value) => ({
                    value: value,
                    name: value,
                })),
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Latest Graded Inspection'] },
            permission: permissionFactory('edukOfstedPersonalDevelopmentScore'),
        },
        [EdukOfstedColumnKey.EFFECTIVENESS_OF_LEADERSHIP_AND_MANAGEMENT_SCORE]: {
            key: 'ofsted.effectivenessOfLeadershipAndManagementScore',
            name: 'Effectiveness of Leadership and Management',
            description: (
                <>
                    <p>Effectiveness of leadership and management at the establishment</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            learnMore(
                                e,
                                ofstedLabelFactory('effectivenessOfLeadershipAndManagementScore')!,
                                ofstedSummaryFactory('effectivenessOfLeadershipAndManagementScore')!,
                                ofstedDescriptionFactory('effectivenessOfLeadershipAndManagementScore')!,
                            );
                        }}
                    >
                        Learn More
                    </a>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label =
                    (
                        props.row as EdukPerson
                    )?.establishment?.ofsted?.effectivenessOfLeadershipAndManagementScore?.toString() || '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.ENUM,
            filterFieldOptions: () =>
                _.map(Object.values(EffectivenessOfLeadershipAndManagementScore), (value) => ({
                    value: value,
                    name: value,
                })),
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Latest Graded Inspection'] },
            permission: permissionFactory('edukOfstedEffectivenessOfLeadershipAndManagementScore'),
        },
        [EdukOfstedColumnKey.IS_SAFEGUARDING_EFFECTIVE]: {
            key: 'ofsted.isSafeguardingEffective',
            name: 'Safeguarding is Effective',
            description: (
                <>
                    <p>Is the safeguarding effective at the establishment</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            learnMore(
                                e,
                                ofstedLabelFactory('isSafeguardingEffective')!,
                                ofstedSummaryFactory('isSafeguardingEffective')!,
                                ofstedDescriptionFactory('isSafeguardingEffective')!,
                            );
                        }}
                    >
                        Learn More
                    </a>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label =
                    (props.row as EdukPerson)?.establishment?.ofsted?.isSafeguardingEffective?.toString() || '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.ENUM,
            filterFieldOptions: () =>
                _.map(Object.values(IsSafeguardingEffective), (value) => ({
                    value: value,
                    name: value,
                })),
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Latest Graded Inspection'] },
            permission: permissionFactory('edukOfstedIsSafeguardingEffective'),
        },
        [EdukOfstedColumnKey.EARLY_YEARS_PROVISION_SCORE]: {
            key: 'ofsted.earlyYearsProvisionScore',
            name: 'Early Years Provision',
            description: (
                <>
                    <p>Score of early years provision if it is provided</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            learnMore(
                                e,
                                ofstedLabelFactory('earlyYearsProvisionScore')!,
                                ofstedSummaryFactory('earlyYearsProvisionScore')!,
                                ofstedDescriptionFactory('earlyYearsProvisionScore')!,
                            );
                        }}
                    >
                        Learn More
                    </a>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label =
                    (props.row as EdukPerson)?.establishment?.ofsted?.earlyYearsProvisionScore?.toString() || '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.ENUM,
            filterFieldOptions: () =>
                _.map(Object.values(EarlyYearsProvisionScore), (value) => ({
                    value: value,
                    name: value,
                })),
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Latest Graded Inspection'] },
            permission: permissionFactory('edukOfstedEarlyYearsProvisionScore'),
        },
        [EdukOfstedColumnKey.SIXTH_FORM_PROVISION_SCORE]: {
            key: 'ofsted.sixthFormProvisionScore',
            name: 'Sixth Form Provision',
            description: (
                <>
                    <p>Score of sixth form provision if it is provided</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            learnMore(
                                e,
                                ofstedLabelFactory('sixthFormProvisionScore')!,
                                ofstedSummaryFactory('sixthFormProvisionScore')!,
                                ofstedDescriptionFactory('sixthFormProvisionScore')!,
                            );
                        }}
                    >
                        Learn More
                    </a>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label =
                    (props.row as EdukPerson)?.establishment?.ofsted?.sixthFormProvisionScore?.toString() || '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.ENUM,
            filterFieldOptions: () =>
                _.map(Object.values(SixthFormProvisionScore), (value) => ({
                    value: value,
                    name: value,
                })),
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Latest Graded Inspection'] },
            permission: permissionFactory('edukOfstedSixthFormProvisionScore'),
        },
        [EdukOfstedColumnKey.PREVIOUS_INSPECTION_START_DATE]: {
            key: 'ofsted.previousInspectionStartDate',
            name: 'Previous Inspection Start Date',
            description: (
                <>
                    <p>Start date of the previous inspection</p>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label =
                    (props.row as EdukPerson)?.establishment?.ofsted?.previousInspectionStartDate?.toString() || '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.DATE,
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Previous Graded Inspection'] },
            permission: permissionFactory('edukOfstedPreviousInspectionStartDate'),
        },
        [EdukOfstedColumnKey.PREVIOUS_INSPECTION_END_DATE]: {
            key: 'ofsted.previousInspectionEndDate',
            name: 'Previous Inspection End Date',
            description: (
                <>
                    <p>End date of the previous inspection</p>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label =
                    (props.row as EdukPerson)?.establishment?.ofsted?.previousInspectionEndDate?.toString() || '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.DATE,
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Previous Graded Inspection'] },
            permission: permissionFactory('edukOfstedPreviousInspectionEndDate'),
        },
        [EdukOfstedColumnKey.PREVIOUS_PUBLICATION_DATE]: {
            key: 'ofsted.previousPublicationDate',
            name: 'Previous Publication Date',
            description: (
                <>
                    <p>Date of the previous publication</p>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label =
                    (props.row as EdukPerson)?.establishment?.ofsted?.previousPublicationDate?.toString() || '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.DATE,
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Previous Graded Inspection'] },
            permission: permissionFactory('edukOfstedPreviousPublicationDate'),
        },
        [EdukOfstedColumnKey.OVERALL_EFFECTIVENESS_PREVIOUS_SCORE]: {
            key: 'ofsted.overallEffectivenessPreviousScore',
            name: 'Overall Effectiveness of Previous Inspection',
            description: (
                <>
                    <p>Overall effectiveness of the previous graded inspection</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            learnMore(
                                e,
                                ofstedLabelFactory('overallEffectivenessPreviousScore')!,
                                ofstedSummaryFactory('overallEffectivenessPreviousScore')!,
                                ofstedDescriptionFactory('overallEffectivenessPreviousScore')!,
                            );
                        }}
                    >
                        Learn More
                    </a>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label =
                    (props.row as EdukPerson)?.establishment?.ofsted?.overallEffectivenessPreviousScore?.toString() ||
                    '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.ENUM,
            filterFieldOptions: () =>
                _.map(Object.values(OverallEffectivenessPreviousScore), (value) => ({
                    value: value,
                    name: value,
                })),
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Previous Graded Inspection'] },
            permission: permissionFactory('edukOfstedOverallEffectivenessPreviousScore'),
        },
        [EdukOfstedColumnKey.CATEGORY_OF_CONCERN_PREVIOUS_SCORE]: {
            key: 'ofsted.categoryOfConcernPreviousScore',
            name: 'Category of Concern of Previous Inspection',
            description: (
                <>
                    <p>Category of concern of the previous graded inspection</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            learnMore(
                                e,
                                ofstedLabelFactory('categoryOfConcernPreviousScore')!,
                                ofstedSummaryFactory('categoryOfConcernPreviousScore')!,
                                ofstedDescriptionFactory('categoryOfConcernPreviousScore')!,
                            );
                        }}
                    >
                        Learn More
                    </a>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label =
                    (props.row as EdukPerson)?.establishment?.ofsted?.categoryOfConcernPreviousScore?.toString() || '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.ENUM,
            filterFieldOptions: () =>
                _.map(Object.values(CategoryOfConcernPreviousScore), (value) => ({
                    value: value,
                    name: value,
                })),
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Previous Graded Inspection'] },
            permission: permissionFactory('edukOfstedCategoryOfConcernPreviousScore'),
        },
        [EdukOfstedColumnKey.QUALITY_OF_EDUCATION_PREVIOUS_SCORE]: {
            key: 'ofsted.qualityOfEducationPreviousScore',
            name: 'Quality of Education of Previous Inspection',
            description: (
                <>
                    <p>Quality of education of the previous graded inspection</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            learnMore(
                                e,
                                ofstedLabelFactory('qualityOfEducationPreviousScore')!,
                                ofstedSummaryFactory('qualityOfEducationPreviousScore')!,
                                ofstedDescriptionFactory('qualityOfEducationPreviousScore')!,
                            );
                        }}
                    >
                        Learn More
                    </a>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label =
                    (props.row as EdukPerson)?.establishment?.ofsted?.qualityOfEducationPreviousScore?.toString() || '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.ENUM,
            filterFieldOptions: () =>
                _.map(Object.values(QualityOfEducationPreviousScore), (value) => ({
                    value: value,
                    name: value,
                })),
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Previous Graded Inspection'] },
            permission: permissionFactory('edukOfstedQualityOfEducationPreviousScore'),
        },
        [EdukOfstedColumnKey.BEHAVIOUR_AND_ATTITUDE_PREVIOUS_SCORE]: {
            key: 'ofsted.behaviourAndAttitudePreviousScore',
            name: 'Behaviour and Attitudes of Previous Inspection',
            description: (
                <>
                    <p>Behaviour and attitudes of the previous graded inspection</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            learnMore(
                                e,
                                ofstedLabelFactory('behaviourAndAttitudePreviousScore')!,
                                ofstedSummaryFactory('behaviourAndAttitudePreviousScore')!,
                                ofstedDescriptionFactory('behaviourAndAttitudePreviousScore')!,
                            );
                        }}
                    >
                        Learn More
                    </a>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label =
                    (props.row as EdukPerson)?.establishment?.ofsted?.behaviourAndAttitudePreviousScore?.toString() ||
                    '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.ENUM,
            filterFieldOptions: () =>
                _.map(Object.values(BehaviourAndAttitudePreviousScore), (value) => ({
                    value: value,
                    name: value,
                })),
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Previous Graded Inspection'] },
            permission: permissionFactory('edukOfstedBehaviourAndAttitudePreviousScore'),
        },
        [EdukOfstedColumnKey.PERSONAL_DEVELOPMENT_PREVIOUS_SCORE]: {
            key: 'ofsted.personalDevelopmentPreviousScore',
            name: 'Personal Development of Previous Inspection',
            description: (
                <>
                    <p>Personal development of the previous graded inspection</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            learnMore(
                                e,
                                ofstedLabelFactory('personalDevelopmentPreviousScore')!,
                                ofstedSummaryFactory('personalDevelopmentPreviousScore')!,
                                ofstedDescriptionFactory('personalDevelopmentPreviousScore')!,
                            );
                        }}
                    >
                        Learn More
                    </a>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label =
                    (props.row as EdukPerson)?.establishment?.ofsted?.personalDevelopmentPreviousScore?.toString() ||
                    '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.ENUM,
            filterFieldOptions: () =>
                _.map(Object.values(PersonalDevelopmentPreviousScore), (value) => ({
                    value: value,
                    name: value,
                })),
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Previous Graded Inspection'] },
            permission: permissionFactory('edukOfstedPersonalDevelopmentPreviousScore'),
        },
        [EdukOfstedColumnKey.EFFECTIVENESS_OF_LEADERSHIP_AND_MANAGEMENT_PREVIOUS_SCORE]: {
            key: 'ofsted.effectivenessOfLeadershipAndManagementPreviousScore',
            name: 'Effectiveness of Leadership and Management of Previous Inspection',
            description: (
                <>
                    <p>Effectiveness of leadership and management of the previous graded inspection</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            learnMore(
                                e,
                                ofstedLabelFactory('effectivenessOfLeadershipAndManagementPreviousScore')!,
                                ofstedSummaryFactory('effectivenessOfLeadershipAndManagementPreviousScore')!,
                                ofstedDescriptionFactory('effectivenessOfLeadershipAndManagementPreviousScore')!,
                            );
                        }}
                    >
                        Learn More
                    </a>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label =
                    (
                        props.row as EdukPerson
                    )?.establishment?.ofsted?.effectivenessOfLeadershipAndManagementPreviousScore?.toString() || '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.ENUM,
            filterFieldOptions: () =>
                _.map(Object.values(EffectivenessOfLeadershipAndManagementPreviousScore), (value) => ({
                    value: value,
                    name: value,
                })),
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Previous Graded Inspection'] },
            permission: permissionFactory('edukOfstedEffectivenessOfLeadershipAndManagementPreviousScore'),
        },
        [EdukOfstedColumnKey.IS_SAFEGUARDING_EFFECTIVE_PREVIOUS_SCORE]: {
            key: 'ofsted.isSafeguardingEffectivePreviousScore',
            name: 'Safeguarding was Effective in Previous Inspection',
            description: (
                <>
                    <p>Whether safeguarding was effective in the previous inspection</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            learnMore(
                                e,
                                ofstedLabelFactory('isSafeguardingEffectivePreviousScore')!,
                                ofstedSummaryFactory('isSafeguardingEffectivePreviousScore')!,
                                ofstedDescriptionFactory('isSafeguardingEffectivePreviousScore')!,
                            );
                        }}
                    >
                        Learn More
                    </a>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label =
                    (
                        props.row as EdukPerson
                    )?.establishment?.ofsted?.isSafeguardingEffectivePreviousScore?.toString() || '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.ENUM,
            filterFieldOptions: () =>
                _.map(Object.values(IsSafeguardingEffectivePreviousScore), (value) => ({
                    value: value,
                    name: value,
                })),
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Previous Graded Inspection'] },
            permission: permissionFactory('edukOfstedIsSafeguardingEffectivePreviousScore'),
        },
        [EdukOfstedColumnKey.EARLY_YEARS_PROVISION_PREVIOUS_SCORE]: {
            key: 'ofsted.earlyYearsProvisionPreviousScore',
            name: 'Early Years Provision at Previous Inspection',
            description: (
                <>
                    <p>Early years provision at the previous graded Inspection</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            learnMore(
                                e,
                                ofstedLabelFactory('earlyYearsProvisionPreviousScore')!,
                                ofstedSummaryFactory('earlyYearsProvisionPreviousScore')!,
                                ofstedDescriptionFactory('earlyYearsProvisionPreviousScore')!,
                            );
                        }}
                    >
                        Learn More
                    </a>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label =
                    (props.row as EdukPerson)?.establishment?.ofsted?.earlyYearsProvisionPreviousScore?.toString() ||
                    '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.ENUM,
            filterFieldOptions: () =>
                _.map(Object.values(EarlyYearsProvisionPreviousScore), (value) => ({
                    value: value,
                    name: value,
                })),
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Previous Graded Inspection'] },
            permission: permissionFactory('edukOfstedEarlyYearsProvisionPreviousScore'),
        },
        [EdukOfstedColumnKey.SIXTH_FORM_PROVISION_PREVIOUS_SCORE]: {
            key: 'ofsted.sixthFormProvisionPreviousScore',
            name: 'Sixth Form Provision at Previous Inspection',
            description: (
                <>
                    <p>Sixth form provision at the previous graded Inspection</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            learnMore(
                                e,
                                ofstedLabelFactory('sixthFormProvisionPreviousScore')!,
                                ofstedSummaryFactory('sixthFormProvisionPreviousScore')!,
                                ofstedDescriptionFactory('sixthFormProvisionPreviousScore')!,
                            );
                        }}
                    >
                        Learn More
                    </a>
                </>
            ),
            sortable: true,
            width: DEFAULT_COLUMN_WIDTH,
            group: ColumnGroup.OFSTED,
            renderCell: (props) => {
                const label =
                    (props.row as EdukPerson)?.establishment?.ofsted?.sixthFormProvisionPreviousScore?.toString() || '';
                return <span title={label}>{label}</span>;
            },
            filterFieldType: filterTypes.FieldType.ENUM,
            filterFieldOptions: () =>
                _.map(Object.values(SixthFormProvisionPreviousScore), (value) => ({
                    value: value,
                    name: value,
                })),
            filterGroups: { [FilterGroups.INSPECTIONS]: ['Previous Graded Inspection'] },
            permission: permissionFactory('edukOfstedSixthFormProvisionPreviousScore'),
        },
    };
}
