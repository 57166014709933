import { ModulePermissions } from '@client/Applications/DataGrids/HelperFunctions/UserPermissionsHelper';
import { UserPermissionsContext } from '@client/Context/UserPermissions';
import {
    AsyncDropDown,
    LogLevel,
    OptionTypeBase,
    PendingButton,
    SearchQuery,
    Sisp,
    isValidString,
    showBanner,
} from '@sprint/sprint-react-components';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { ClientGroupsRequest } from '../../Api/ClientGroupsRequest';
import { CustomPropertiesDataTypeRequest } from '../../Api/CustomPropertiesDataTypeRequest';
import { CustomPropertiesRequest } from '../../Api/CustomPropertiesRequest';
import { CustomPropertiesTypeRequest } from '../../Api/CustomPropertiesTypeRequest';
import LimitReachedAsyncDropDown from '../../Components/LimitReachedAsyncDropDown';
import { KnowledgeBaseUrlKey, KnowledgeBaseUrls } from '../../HelperFunctions/KnowledgeBaseUrls';
import { RepositoryFactoryContext } from '../../index';
import ClientGroup from '../../Models/ClientGroup';
import CustomProperty from '../../Models/CustomProperty';
import CustomPropertyDataType from '../../Models/CustomPropertyDataType';
import { ClientGroupType } from '../../Models/Enums';
import LimitIssue from '../../Models/LimitIssue';
import './CustomPropertiesAddSisp.scss';

interface Props {
    shown: boolean;
    onClose: () => void;
    onSuccess: (event: any) => Promise<boolean>;
    limits: LimitIssue[];
}

const CustomPropertiesAddSisp: FunctionComponent<Props> = (props: Props) => {
    const userPermissions = useContext(UserPermissionsContext);
    const customPropertiesRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new CustomPropertiesRequest(),
    );
    const customPropertiesTypeRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new CustomPropertiesTypeRequest(),
    );
    const customPropertiesDataTypeRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new CustomPropertiesDataTypeRequest(),
    );
    const customPropertiesClientGroupsRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new ClientGroupsRequest(ClientGroupType.CUSTOM_PROPERTIES),
    );

    const focusRef = useRef<HTMLInputElement>(null);
    const [dropDownKey, setDropDownKey] = useState<string>('');

    const [showSaveAndAdd, setShowSaveAndAdd] = useState<boolean>(true);

    const [dropdownOptions, setDropdownOptions] = useState<CustomPropertyDataType[]>([]);
    const [dropdownLoaded, setDropdownLoaded] = useState<boolean>(false);

    const [hasOptions, setHasOptions] = useState<boolean>(false);

    const [newName, setNewName] = useState<string>('');
    const [newNameValid, setNewNameValid] = useState<boolean>(true);

    const [newDescription, setNewDescription] = useState<string>('');

    const [newPropertyType, setNewPropertyType] = useState<OptionTypeBase | null>(null);
    const [newPropertyTypeValid, setNewPropertyTypeValid] = useState<boolean>(true);

    const [dataType, setDataType] = useState<string | null>(null);
    const [dataTypeValid, setDataTypeValid] = useState<boolean>(true);

    const [newOptions, setNewOptions] = useState<string>('');
    const [newOptionsValid, setNewOptionsValid] = useState<boolean>(true);

    const [newClientGroup, setNewClientGroup] = useState<OptionTypeBase | null>(null);

    const [newSortOrder, setNewSortOrder] = useState<string>('');
    const [newSortOrderValid, setNewSortOrderValid] = useState<boolean>(true);

    const [newShowInForms, setShowInForms] = useState<boolean>(false);
    const [newShowInViews, setShowInViews] = useState<boolean>(false);

    const [isSaving, setIsSaving] = useState<boolean>(false);

    useEffect(() => {
        collectDataTypeDropdownOptions();
    }, []);

    useEffect(() => {
        if (props.shown) {
            setDropDownKey(uuidv4());
            focusRef.current?.focus();
        }
    }, [props.shown]);

    // When a new property is added the limits API call is ran
    // after and the limits props is updated.
    // In the case of Save & Add Another option we need to make
    // sure that the type dropdown options are reloaded so that
    // the latest limits are used.
    useEffect(() => {
        if (props.shown && props.limits.length > 0) {
            setDropDownKey(uuidv4());
        }
    }, [props.limits]);

    const validate = async (): Promise<boolean> => {
        const nameValid = !!newName && isValidString(newName);
        setNewNameValid(nameValid);

        const propertyTypeValid = !!newPropertyType;
        setNewPropertyTypeValid(propertyTypeValid);

        const dataTypeValid = !!dataType;
        setDataTypeValid(dataTypeValid);

        let optionsValid = true;
        if (hasOptions) {
            const tokenArray = newOptions.split('\n');
            tokenArray.forEach((element) => {
                optionsValid = optionsValid && element.trim().length > 0;
            });
        }
        setNewOptionsValid(optionsValid);

        const sortOrderValid = !!newSortOrder;
        setNewSortOrderValid(sortOrderValid);

        return nameValid && dataTypeValid && optionsValid && sortOrderValid;
    };

    const reset = () => {
        setNewName('');
        setNewDescription('');
        setNewPropertyType(null);
        setDataType(null);
        setNewOptions('');
        setNewClientGroup(null);
        setNewSortOrder('');
        setShowInForms(false);
        setShowInViews(false);

        setNewNameValid(true);
        setNewPropertyTypeValid(true);
        setDataTypeValid(true);
        setNewOptionsValid(true);
        setNewSortOrderValid(true);
    };

    const collectDataTypeDropdownOptions = async () => {
        // Do the API collect on load
        // Update this function to iterate over a list in memory
        const query = new SearchQuery(1, 100);
        return customPropertiesDataTypeRepository
            .search(query)
            .then((results: any) => {
                setDropdownOptions(results.results);
                setDropdownLoaded(true);
            })
            .catch((err: any) => {
                return null;
            });
    };

    const dropdownMapLambda = (element: CustomPropertyDataType | OptionTypeBase) => {
        return {
            value: element.value,
            label: element.label,
        };
    };

    const filterDataTypeDropdownOptions = async (filter: string) => {
        if (filter.length == 0) {
            return dropdownOptions.map(dropdownMapLambda);
        } else {
            return dropdownOptions
                .filter((option: CustomPropertyDataType) => option.label?.toLowerCase().includes(filter.toLowerCase()))
                .map(dropdownMapLambda);
        }
    };

    const getPropertyTypes = async (filter: string) => {
        const query = new SearchQuery(1, 100);
        return customPropertiesTypeRepository
            .search(query)
            .then((results: any) => {
                const propertyTypes =
                    filter.length == 0
                        ? results.results
                        : results.results
                              .filter((option: OptionTypeBase) =>
                                  option.label?.toLowerCase().includes(filter.toLowerCase()),
                              )
                              .map(dropdownMapLambda);
                return checkTypeLimits(propertyTypes);
            })
            .catch((err: any) => {
                return null;
            });
    };

    const getClientGroups = async (filter: string) => {
        const query = new SearchQuery(1, 1000);
        if (filter.length > 0) {
            query.setFilter(filter);
        }
        return customPropertiesClientGroupsRepository
            .search(query)
            .then((results: any) => {
                return results.results.map((result: ClientGroup) => {
                    return { value: result.id, label: result.name };
                });
            })
            .catch((err: any) => {
                return null;
            });
    };

    const checkTypeLimits = (propertyTypes: OptionTypeBase[]) => {
        if (props.limits.length < 1) {
            return propertyTypes;
        } else {
            return propertyTypes.map((propertyType: OptionTypeBase) => {
                const limitReached = props.limits.filter(
                    (limit: LimitIssue) => propertyType.value == limit.type && !limit.near_limit,
                );
                return limitReached.length > 0 ? { ...propertyType, isDisabled: true } : propertyType;
            });
        }
    };

    const handleAddRow = async (): Promise<boolean> => {
        const CustomProperty: CustomProperty = {
            name: newName,
            description: newDescription,
            type: newPropertyType!.value,
            data_type: dataType as string,
            sort_order: newSortOrder,
            available_in_forms: newShowInForms,
            show_in_view: newShowInViews,
            archived: false,
            options: newOptions,
            client_group_info: newClientGroup ? { id: newClientGroup.value, name: newClientGroup.label } : null,
        };

        return customPropertiesRepository
            .create(CustomProperty)
            .then(props.onSuccess)
            .then(async (success) => {
                reset();
                return success;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to create Custom Property - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return false;
            });
    };

    const onSubmitForm = async (e: FormEvent) => {
        setIsSaving(true);
        e.preventDefault();
        if ((await validate()) && (await handleAddRow())) props.onClose();
        setIsSaving(false);
    };

    const onSubmitAndAddAnother = async (e: FormEvent) => {
        setIsSaving(true);
        e.preventDefault();
        if ((await validate()) && (await handleAddRow())) {
            reset();
        }
        setIsSaving(false);
    };

    return (
        <Sisp
            isOpen={props.shown}
            onSubmit={handleAddRow}
            onCancel={() => {
                reset();
                props.onClose();
            }}
            validate={validate}
            footerOverride={
                <>
                    <Button
                        variant="default"
                        onClick={() => {
                            reset();
                            props.onClose();
                        }}
                    >
                        Cancel
                    </Button>
                    {showSaveAndAdd && (
                        <PendingButton variant="default" onClick={onSubmitAndAddAnother} pending={isSaving}>
                            Save & Add Another
                        </PendingButton>
                    )}
                    <PendingButton variant="primary" onClick={onSubmitForm} pending={isSaving}>
                        Save
                    </PendingButton>
                </>
            }
        >
            <h4>Add Custom Property</h4>
            {!dropdownLoaded && (
                <div style={{ position: 'relative', alignItems: 'center' }}>
                    <Card
                        className="loading-spinner-container filter-loading-spinner"
                        style={{ background: '#f9f9f9' }}
                    >
                        <Spinner animation="border" role="status" />
                    </Card>
                </div>
            )}
            {dropdownLoaded && (
                <Form onSubmit={onSubmitForm}>
                    <Form.Group>
                        <Form.Label className="type-label-option">
                            Type <span className="required-field-marker">*</span>
                            <a
                                className="type-option-helper"
                                target="_blank"
                                href={KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.CREATE_CUSTOM_PROPERTY)}
                            >
                                I'm not sure
                            </a>
                        </Form.Label>
                        <LimitReachedAsyncDropDown
                            stateKey={dropDownKey}
                            value={newPropertyType}
                            isInvalid={!newPropertyTypeValid}
                            onChange={(option: OptionTypeBase) => {
                                setNewPropertyType(option);
                            }}
                            loadOptions={getPropertyTypes}
                            menuPortalTarget={document.body}
                        />
                        <Form.Control.Feedback type="invalid">
                            {!newPropertyTypeValid && !newPropertyType && 'This field is required.'}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Name <span className="required-field-marker">*</span>
                        </Form.Label>
                        <Form.Control
                            autoComplete="off"
                            type="text"
                            isInvalid={!newNameValid}
                            value={newName || ''}
                            onChange={(event) => {
                                setNewName(event.target.value);
                                setNewNameValid(true);
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            {!newNameValid && newName == '' && 'This field is required.'}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            autoComplete="off"
                            type="text"
                            value={newDescription || ''}
                            onChange={(event) => {
                                setNewDescription(event.target.value);
                            }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Data Type <span className="required-field-marker">*</span>
                        </Form.Label>
                        <AsyncDropDown
                            value={
                                dataType != null
                                    ? dropdownOptions.find((option: OptionTypeBase) => option.value == dataType)
                                    : null
                            }
                            isInvalid={false}
                            onChange={(option: OptionTypeBase) => {
                                const selectedItem = option?.value ?? null;
                                setDataType(selectedItem);
                                setDataTypeValid(true);
                                setNewOptions('');
                                setHasOptions(
                                    dropdownOptions.find((option: OptionTypeBase) => option.value == selectedItem)
                                        ?.options ?? false,
                                );
                            }}
                            loadOptions={filterDataTypeDropdownOptions}
                            isClearable={false}
                            menuPortalTarget={document.body}
                        />
                        <Form.Control.Feedback type="invalid">
                            {!dataTypeValid && dataType == null && 'This field is required.'}
                        </Form.Control.Feedback>
                    </Form.Group>
                    {hasOptions && (
                        <Form.Group>
                            <Form.Label>
                                Options <span className="required-field-marker">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                as="textarea"
                                rows={4}
                                value={newOptions || ''}
                                onChange={(event) => {
                                    const values = event.target.value;
                                    setNewOptions(values);
                                }}
                            />
                            <span className="help-block">One option per line.</span>
                            <Form.Control.Feedback type="invalid">
                                {!newOptionsValid && 'This field is required. Please ensure there are no empty lines.'}
                            </Form.Control.Feedback>
                        </Form.Group>
                    )}
                    {userPermissions.clientGroupsCustomProperties === ModulePermissions.ENABLED && (
                        <Form.Group>
                            <Form.Label>Group</Form.Label>
                            <AsyncDropDown
                                value={newClientGroup}
                                isInvalid={false}
                                onChange={(option: OptionTypeBase) => {
                                    setNewClientGroup(option);
                                }}
                                loadOptions={getClientGroups}
                                isClearable={true}
                                menuPortalTarget={document.body}
                            />
                        </Form.Group>
                    )}
                    <Form.Group>
                        <Form.Label>
                            Sort Order <span className="required-field-marker">*</span>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            isInvalid={!newSortOrderValid}
                            value={newSortOrder || ''}
                            onChange={(event) => {
                                const valueNumbersOnly = event.target.value.replace(/\D/g, '');
                                setNewSortOrder(valueNumbersOnly);
                                setNewSortOrderValid(true);
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            {!newSortOrderValid && newSortOrder == '' && 'This field is required.'}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Check
                            id="show_in_forms"
                            name="show_in_forms"
                            type="checkbox"
                            label="Show in Forms"
                            custom
                            checked={newShowInForms}
                            onChange={() => setShowInForms(!newShowInForms)}
                        />
                        {newPropertyType && (
                            <Form.Check
                                id="show_in_view_pages"
                                name="show_in_view_pages"
                                type="checkbox"
                                label={`Show in ${newPropertyType.label} View Pages`}
                                custom
                                checked={newShowInViews}
                                onChange={() => setShowInViews(!newShowInViews)}
                            />
                        )}
                    </Form.Group>
                </Form>
            )}
        </Sisp>
    );
};

export default CustomPropertiesAddSisp;
